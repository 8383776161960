import React from "react";
import FormControl from "./FormControl.js";
import {Checkbox as MuiCheckbox, Tooltip} from "@material-ui/core";

/**
 * Checkbox wrapper
 *
 * @package BrandTracker
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class Checkbox extends React.PureComponent {

	/**
	 * Changed.
	 *
	 * @param {Event} e
	 * @param {Boolean} v
	 * @return {void}
	 */
	handleChange = (e, v) => {
		e.stopPropagation();
		this.props.onChange(v, this.props.value, this.props.name);
	};


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Tooltip title={(this.props.title || "")}>
				<div>
					<FormControl label={this.props.label}>
						<MuiCheckbox
							style={this.props.style}
							checked={this.props.checked}
							color="primary"
							disabled={this.props.disabled}
							indeterminate={this.props.indeterminate}
							onChange={this.handleChange} />
					</FormControl>
				</div>
			</Tooltip>
		);
	}

}

export default Checkbox;
