import React from "react";
import Button from "Components/Button.js";
import Config from "Resources/Config.json";
import Container from "Components/Container.js";
import UiBarAction from "./UiBarAction.js";
import UiBarBase from "./UiBarBase.js";
import UiBarSearch from "./UiBarSearch.js";
import withMobile from "Hoc/withMobile.js";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

/**
 * UI bar.
 *
 * Renders the app navigation bar.
 *
 * Please refer to the source for operational details.
 *
 * @package BrandTracker
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class UiBar extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<UiBarBase>
				{(this.props.auth ? this.renderAuthed() : this.renderPublic())}
			</UiBarBase>
		);
	}


	/**
	 * Render an action.
	 *
	 * @param {Object} action `UiActions.js`
	 * @param {mixed} key React node key
	 * @return {ReactNode}
	 */
	renderAction(action, key) {
		return (
			<UiBarAction
				action={action}
				key={key}
				onClick={this.props.onNavigate} />
		);
	}


	/**
	 * Render the actions to an array of rendered actions.
	 *
	 * @return {Array} `ReactNode` of `UiActions.js`
	 */
	renderActions() {
		return this.props.actions.map((action, key) => (
			this.renderAction(action, key)
		));
	}


	/**
	 * Render the actions main section.
	 * 
	 * @return {ReactNode}
	 */
	renderActionsMain() {
		return (
			<Container row={true}>
				{this.renderActions()}
			</Container>
		);
	}


	/**
	 * Render the authenticated variant.
	 *
	 * @return {ReactNode}
	 */
	renderAuthed() {
		return (
			<React.Fragment>
				<UiBarSearch />
				{(!this.props.isMobile ? this.renderActionsMain() : null)}
			</React.Fragment>
		);
	}


	/**
	 * Render the public variant.
	 *
	 * @return {ReactNode}
	 */
	renderPublic() {
		return (
			<Button
				label="Login"
				variant="contained"
				uri={Config.uris.login} />
		);
	}

}

export default connect(({auth}) => ({auth}))(withMobile(withRouter(UiBar)));
