/**
 * Statcounter handler
 *
 * @package BrandTracker
 * @subpackage Integrations
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
class Statcounter {

	/**
	 * Enable Statcounter use.
	 *
	 * @return {void}
	 */
	static enable() {

		window.sc_invisible = 1;
		window.sc_project = 12665504;
		window.sc_security = "61a77cbc";

		const sc = document.createElement("script");
		sc.async = true;
		sc.id = this.SCRIPT_ID;
		sc.type = "text/javascript";
		sc.src = "https://www.statcounter.com/counter/counter.js";
		document.body.appendChild(sc);

	}


	/**
	 * Disable Statcounter use.
	 *
	 * @return {void}
	 */
	static disable() {
		const script = document.querySelector(`#${this.SCRIPT_ID}`);
		if (script) script.parentNode.removeChild(script);
	}


	/**
	 * `id` attribute to set on our `<script>` element
	 *
	 * @type {String}
	 */
	static SCRIPT_ID = "statcounter";

}

export default Statcounter;
