import React from "react";
import Application from "./Application.js";
import DayJs from "@date-io/dayjs";
import ErrorView from "Views/ErrorView.js";
import History from "./History.js";
import Snackbars from "Resources/Snackbars.json";
import {Store, StorePersistor} from "./Store.js";
import ThemeProvider from "./ThemeProvider.js";
import {SnackbarHost} from "./Snackbar.js";
import {SnackbarProvider} from "notistack";
import {CssBaseline, withStyles} from "@material-ui/core";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {PersistGate} from "redux-persist/integration/react";
import {Provider as ReduxProvider} from "react-redux";
import {Router} from "react-router-dom";
import * as Sentry from "@sentry/react";

/**
 * App
 *
 * BrandTracker's app class.
 *
 * @package BrandTracker
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class App extends React.Component {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Sentry.ErrorBoundary fallback={<ErrorView />}>
				<ReduxProvider store={Store}>
					<PersistGate loading={null} persistor={StorePersistor}>
						<MuiPickersUtilsProvider utils={DayJs}>
							<ThemeProvider>
								<CssBaseline />
								<SnackbarProvider {...this.notistackProps} {...Snackbars}>
									<Sentry.ErrorBoundary fallback={<ErrorView />}>
										<Router history={History}>
											<Application />
										</Router>
									</Sentry.ErrorBoundary>
									<SnackbarHost />
								</SnackbarProvider>
							</ThemeProvider>
						</MuiPickersUtilsProvider>
					</PersistGate>
				</ReduxProvider>
			</Sentry.ErrorBoundary>
		);
	}


	/**
	 * Notistack configuration.
	 * 
	 * @reurn {Object}
	 */
	get notistackProps() {
		return {
			classes: {
				containerAnchorOriginBottomCenter: this.props.classes.notistackAlert,
				variantSuccess: this.props.classes.notistackSuccess
			}
		};
	}


	/**
	 * App-level CSS classes.
	 * 
	 * @type {Object}
	 */
	static classes = {
		notistackAlert: {
			zIndex: 10000000
		},
		notistackSuccess: {
			backgroundColor: "var(--base-color)",
			color: "var(--theme-color)"
		}
	};

}

export default withStyles(App.classes)(App);
