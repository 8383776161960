import React from "react";
import {withSnackbar as notistack} from "notistack";

/**
 * `withSnackbar`
 *
 * The snackbar conversion wrapper.
 *
 * @package BrandTracker
 * @subpackage Hoc
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
const withSnackbar = Component => {

	/**
	 * Component wrapped with `withSnackbar`.
	 *
	 * @package BrandTracker
	 * @subpackage Hoc
	 * @author Heron Web Ltd
	 * @copyright BrandTracker
	 */
	const cws = class ComponentWithSnackbar extends React.PureComponent {

		/**
		 * Render.
		 * 
		 * @return {ReactNode}
		 */
		render() {
			return <Component {...this.props} snack={this.snack} />;
		}


		/**
		 * Display a snackbar.
		 *
		 * We automatically `preventDuplicate` unless overridden for errors.
		 * 
		 * @param {String} msg All values are automatically cast to string
		 * @param {String} variant optional Defaults to `default`/`error`
		 * @param {Boolean} preventDuplicate optional See `notistack`/above
		 * @return {void}
		 */
		snack = (msg, variant=null, preventDuplicate=undefined) => {
			if (!variant) {
				variant = (!(msg instanceof Error) ? "default" : "error");
			}
			if (preventDuplicate === undefined) {
				preventDuplicate = (variant === "error");
			}
			this.props.enqueueSnackbar(`${msg}`, {variant, preventDuplicate});
		};

	};

	return notistack(cws);

};

export default withSnackbar;
