import React from "react";

/**
 * React utility methods
 *
 * @package BrandTracker
 * @subpackage Helpers
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class ReactHelper {

	/**
	 * Get a new render key.
	 * 
	 * @return {Integer}
	 */
	static getNewKey() {
		const time = (new Date()).getTime();
		return (Math.floor(Math.random(1, 1000000) * 100) + time);
	}


	/**
	 * Render a component instance.
	 *
	 * @param {ReactComponent} Component
	 * @param {Object} props optional
	 * @return {ReactNode}
	 */
	static render(Component, props={}) {
		return <Component {...props} />;
	}

}

export default ReactHelper;
