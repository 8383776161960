import React from "react";
import {FormControl, InputLabel, MenuItem, OutlinedInput, Select as MuiSelect} from "@material-ui/core";

/**
 * Select component
 *
 * An extension of Material's Select component that supports declarative 
 * option rendering via an `options` prop as an array of objects with 
 * properties for `label` and `value`.
 *
 * Set the Select label via the `label` prop.
 *
 * Supports dynamic handling of `null` values by casting to and from 
 * boolean `false` (as `null` is unsupported by the Material Select).
 *
 * Please refer to the source for complete operational details.
 * 
 * @package BrandTracker
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class Select extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Open?
			 *
			 * @type {Boolean}
			 */
			open: false

		};

		/**
		 * Method binds
		 */
		this.handleChange = this.handleChange.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleOpen = this.handleOpen.bind(this);

	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		if (this.props.label) {
			return this.renderLabelled();
		}
		else return this.renderSelect();
	}


	/**
	 * Render labelled.
	 * 
	 * @return {ReactNode}
	 */
	renderLabelled() {
		return (
			<FormControl margin="dense" size="small" variant="outlined">
				<InputLabel margin="dense">{this.props.label}</InputLabel>
				{this.renderSelect()}
			</FormControl>
		);
	}


	/**
	 * Render the actual selection box.
	 * 
	 * @return {ReactNode}
	 */
	renderSelect() {
		return (
			<MuiSelect
				classes={this.props.classes}
				disabled={this.props.disabled}
				input={<OutlinedInput label={this.props.label} margin="dense" />}
				inputProps={this.constructor.inputProps}
				onChange={this.handleChange}
				onClose={this.handleClose}
				onOpen={this.handleOpen}
				open={this.state.open}
				ref={this.ref}
				style={this.props.style}
				value={this.value}
				variant={(this.props.variant || "outlined")}>
				{this.renderOptions()}
			</MuiSelect>
		);
	}


	/**
	 * We're opening.
	 *
	 * @return {void}
	 */
	handleOpen() {
		this.setState({open: true});
	}


	/**
	 * We're closing.
	 *
	 * @return {void}
	 */
	handleClose(e) {
		this.setState({open: false});
		if (!(e.nativeEvent instanceof KeyboardEvent)) {
			setTimeout(() => document.activeElement.blur(), 50);
		}
	}


	/**
	 * The value has been changed.
	 *
	 * We may need to cast back to `null`.
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	handleChange(e) {
		let attr = null;
		if (e.target?.getAttribute) {
			attr = e.target.getAttribute("data-value");
		}
		const val = e.target.value;
		this.props.onChange(this.resolveValueReverse(val || attr));
	}


	/**
	 * Render our options to an array of React nodes from the `options` prop.
	 *
	 * @return {Array} Array of React nodes for our option items
	 */
	renderOptions() {
		return this.props.options.map(({changeOnClick, label, value}, key) => {
			return (
				<MenuItem
					key={key}
					onClick={(changeOnClick ? this.handleChange : undefined)}
					value={this.resolveValue(value)}>
					{label}
				</MenuItem>
			);
		});
	}


	/**
	 * Resolve an external value to internal state.
	 *
	 * This takes care of `null` (which is ignored by Material select).
	 *
	 * @param {mixed} value
	 * @return {mixed}
	 */
	resolveValue(value) {
		return ((value !== null) ? value : false);
	}


	/**
	 * Reverse `resolveValue()` when reporting a value externally.
	 * 
	 * @param {mixed} value
	 * @return {mixed}
	 */
	resolveValueReverse(value) {
		return ((value !== false) ? value : null);
	}


	/**
	 * Get the current value.
	 *
	 * We handle a `null` value as an empty string.
	 *
	 * @return {mixed}
	 */
	get value() {
		return this.resolveValue(this.props.value);
	}


	/**
	 * Menu input props.
	 *
	 * This stops the body jumping on menu open (e.g. #190).
	 * 
	 * @type {Object}
	 */
	static inputProps = {MenuProps: {disableScrollLock: true, transitionDuration: 0}};

}

export default Select;
