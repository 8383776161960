import React from "react";
import Container from "Components/Container.js";
import MenuButton from "Components/MenuButton.js";
import Styles from "Resources/Styles.json";
import rem from "Helpers/Rem.js";
import scss from "./UiBarBase.module.scss";
import withDrawer from "Hoc/withDrawer.js";
import withMobile from "Hoc/withMobile.js";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {AppBar, Box, Toolbar, withTheme} from "@material-ui/core";
import ImageLogo from "Components/ImageLogo";

/**
 * UI bar base component
 *
 * @package BrandTracker
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class UiBarBase extends React.Component {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<AppBar
				className={(this.translucent ? this.translucentClasses : null)}
				color="inherit"
				position="fixed"
				style={this.constructor.styles}>
				<Box px={1} style={this.constructor.containerStyles}>
					<Toolbar {...this.toolbarProps}>
						<Container
							row={true}
							style={this.constructor.logoContainerStyles}>
							{this.renderButton()}
							{(!this.isMobile && <ImageLogo link={true} noDefaultClasses={true} />)}
						</Container>
						{this.props.children}
					</Toolbar>
				</Box>
			</AppBar>
		);
	}


	/**
	 * Render the button.
	 * 
	 * @return {ReactNode}
	 */
	renderButton() {
		if (!this.props.drawerVisible && this.isMobile) return <ImageLogo mini={true} link={true} noDefaultClasses={true} />;
		else if (this.props.drawerVisible && this.isMobile) return <MenuButton />;
		else return null;
	}

	/**
	 * Get whether we're using dark theme.
	 * 
	 * @return {Boolean}
	 */
	get darkTheme() {
		return (this.props.theme.palette.type === "dark");
	}


	/**
	 * Get whether we're in mobile viewport mode.
	 *
	 * @return {Boolean}
	 */
	get isMobile() {
		return !this.props.bpGt("lg");
	}


	/**
	 * `Toolbar` `grid-template-columns`.
	 * 
	 * @return {String} CSS
	 */
	get toolbarColumns() {
		const cols = [
			(!this.isMobile ? "18rem" : "max-content"),
			(this.props.auth ? "1fr" : ""),
			"max-content"
		];
		return cols.join(" ");
	}


	/**
	 * `Toolbar` prop definitions.
	 *
	 * @return {Object}
	 */
	get toolbarProps() {
		return {
			disableGutters: true,
			style: {
				display: "grid",
				gap: rem(),
				gridTemplateColumns: this.toolbarColumns,
				height: "100%",
				justifyContent: "space-between",
				minHeight: "100%"
			}
		};
	}


	/**
	 * Get whether we should be translucent.
	 * 
	 * @return {Boolean}
	 */
	get translucent() {
		return (!this.props.auth && (this.props.history.location.pathname === "/"));
	}


	/**
	 * Get translucent classes.
	 * 
	 * @return {Array}
	 */
	get translucentClasses() {
		const classes = [scss.translucent];
		if (this.darkTheme) classes.push(scss.translucentDark);
		return classes.join(" ");
	}


	/**
	 * Container styles.
	 * 
	 * @type {Object}
	 */
	static containerStyles = {
		height: "100%"
	};


	/**
	 * Logo container styles.
	 * 
	 * @type {Object}
	 */
	static logoContainerStyles = {
		alignItems: "center",
		gap: "0rem",
		gridTemplateRows: "100%",
		height: "100%"
	};

	/**
	 * App bar styles.
	 * 
	 * @type {Object}
	 */
	static styles = {
		height: Styles.ui_bar_height,
		maxHeight: Styles.ui_bar_height,
		justifyContent: "center",
		zIndex: 99999
	};
}

export default connect(({auth}) => ({auth}))(withDrawer(withMobile(withRouter(withTheme(UiBarBase)))));
