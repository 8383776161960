import api from "api.js";

/**
 * Auth service
 *
 * Methods for interacting with the authentication API.
 *
 * @package BrandTracker
 * @subpackage Auth
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class AuthService {

	/**
	 * Authenticate a user account.
	 *
	 * Returns an Axios Promise which will resolve with the user's UUID 
	 * when authentication is successful.
	 *
	 * @param {String} email This is not validated
	 * @param {String} password This is not validated
	 * @return {Promise}
	 */
	static auth(email, password) {
		const data = {email, password};
		return api.axios().post("/auth", data).then(auth => {
			return {auth: auth.data, user: this.getJwtUser(auth.data)};
		});
	}


	/**
	 * Get the user object payload from an API JWT.
	 *
	 * @param {String} jwt Valid JWT from the authentication API
	 * @return {Object} User object contained as the JWT's payload
	 */
	static getJwtUser(jwt) {
		const pl = jwt.split(".")[1];
		return JSON.parse(atob(pl.replace(/-/g, "+").replace(/_/g, "/")));
	}

}

export default AuthService;
