import React from "react";
import Button from "Components/Button.js";
import Container from "Components/Container.js";
import Loader from "Components/Loader.js";
import * as mui from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import scss from "./Dialog.module.scss";

/**
 * Dialog component
 *
 * @package BrandTracker
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class Dialog extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<mui.Dialog
				classes={scss}
				className={this.props.className}
				disableBackdropClick={this.disabled}
				disableEscapeKeyDown={(this.disabled || this.props.noEscape)}
				disableScrollLock={true}
				fullWidth={true}
				maxWidth={this.props.maxWidth}
				onClose={this.props.onClose}
				open={this.props.open}
				PaperProps={this.props.PaperProps}
				style={this.props.style}
				transitionDuration={(this.props.noTransition ? 0 : undefined)}>
				{(this.shouldRenderTitle ? this.renderTitle() : null)}
				<mui.DialogContent
					ref={this.props.contentRef}
					style={this.props.DialogContentStyles}>
					<Container singleColumn={true} spacing={0.5}>
						<Container
							mb={(this.props.mb ? 0.5 : undefined)}
							singleColumn={true}
							spacing={0.5}>
							{this.props.children}
						</Container>
						{(this.props.loading ? <Loader size={30} /> : null)}
					</Container>
				</mui.DialogContent>
				{(!this.props.noActions ? this.renderActions() : null)}
			</mui.Dialog>
		);
	}


	/**
	 * Render the actions.
	 * 
	 * @return {ReactNode}
	 */
	renderActions() {
		return (
			<mui.DialogActions>
				{(this.props.cancellable ? this.renderCancel() : null)}
				<Button
					disabled={(this.disabled || this.props.okDisabled)}
					label={(this.props.okLabel || "OK")}
					onClick={this.okAction} />
			</mui.DialogActions>
		);
	}


	/**
	 * Render the cancel button.
	 * 
	 * @return {ReactNode}
	 */
	renderCancel() {
		return (
			<Button
				disabled={this.disabled}
				label="Cancel"
				onClick={this.props.onClose}
				variant="outlined" />
		);
	}


	/**
	 * Render the close icon.
	 * 
	 * @return {ReactNode}
	 */
	renderCloseIcon() {
		return (
			<Container pr={1}>
				<mui.IconButton
					disabled={this.disabled}
					onClick={this.props.onClose}>
					<CloseIcon />
				</mui.IconButton>
			</Container>
		);
	}


	/**
	 * Render the table's title.
	 * 
	 * @return {ReactNode}
	 */
	renderTitle() {
		return (
			<Container alignItems="center" justifyContent="space-between" row={true}>
				<mui.DialogTitle>{this.props.title}</mui.DialogTitle>
				{(this.props.closeIcon ? this.renderCloseIcon() : null)}
			</Container>
		);
	}


	/**
	 * Get whether we should be disabled.
	 * 
	 * @return {Boolean}
	 */
	get disabled() {
		return (this.props.disabled || this.props.loading);
	}


	/**
	 * Get the action for the "OK" button.
	 * 
	 * @return {Function}
	 */
	get okAction() {
		return (this.props.cancellable ? this.props.onOk : this.props.onClose);
	}


	/**
	 * Get whether we should render a title for the table.
	 * 
	 * @return {Boolean}
	 */
	get shouldRenderTitle() {
		return !!this.props.title;
	}

}

export default Dialog;
