import React from "react";
import Checkbox from "Components/Checkbox.js";
import dS3Media from "Dispatchers/dS3Media.js";
import {connect} from "react-redux";

/**
 * F12 S3 media checkbox
 *
 * @package BrandTracker
 * @subpackage F12
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class F12S3Media extends React.Component {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Checkbox
				checked={this.props.s3Media}
				label="S3 media driver"
				onChange={dS3Media} />
		);
	}

}

export default connect(({s3Media}) => ({s3Media}))(F12S3Media);
