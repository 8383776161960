export default async () => {
	if (process.env.NODE_ENV === "production") {
		if ("serviceWorker" in navigator) {

			const sw = navigator.serviceWorker.getRegistration();

			try {
				if (sw) await sw.update();
				else await navigator.serviceWorker.register("/sw.js", {updateViaCache: "none"});
			}
			catch (e) {
				// ...
			}

		}
	}
};
