import {lazy} from "react";

export default [
	{
		uri: "/login",
		component: lazy(() => import("Login/LoginView.js")),
		public: true,
		publicOnly: true
	},
	{
		uri: "/account/pwr/:token",
		component: lazy(() => import("Pwr/PwrView.js")),
		public: true,
		publicOnly: true
	},
	{
		uri: "/account",
		component: lazy(() => import("Profile/ProfileView.js"))
	},
	{
		uri: "/admin*",
		component: lazy(() => import("Admin/AdminView.js"))
	},
	{
		uri: "/content/:uuid",
		component: lazy(() => import("Content/ContentDetailView.js"))
	},
	{
		uri: "/content",
		component: lazy(() => import("Content/ContentView.js"))
	},
	{
		uri: "/collections",
		component: lazy(() => import("Featured/FeaturedView.js"))
	},
	{
		uri: "/insights",
		component: lazy(() => import("Views/ComingSoonView.js"))
	},
	{
		uri: "/reports/:report",
		component: lazy(() => import("Mcr/McrView.js")),
		public: true
	},
	{
		uri: "/powerbi",
		component: lazy(() => import("PowerBi/PowerBiView.js"))
	},
	{
		uri: "/f12",
		component: lazy(() => import("F12/F12View.js")),
		public: true
	},
	{
		uri: "/contact-us",
		component: lazy(() => import("Index/IndexContact.js")),
		public: true
	},
	{
		uri: "/privacy-policy",
		component: lazy(() => import("Index/IndexPrivacy.js")),
		public: true
	},
	{
		uri: "/terms-of-service",
		component: lazy(() => import("Index/IndexTos.js")),
		public: true
	},
	{
		uri: "/",
		component: lazy(() => import("Index/IndexView.js")),
		public: true
	},
	{
		uri: "*",
		component: lazy(() => import("Views/E404View.js")),
		public: true
	}
];
