import React from "react";
import scss from "Ui/UiBarBase.module.scss";
import Logo from "Components/Logo.js";
import Image from "Components/Image.js";
import Link from "Components/Link";
import {withTheme} from "@material-ui/core";
import rem from "Helpers/Rem.js";

class ImageLogo extends React.Component {

	logoMain() {
		return this.props.noAnimation ? null : scss.logoMain;
	}

	miniLogo() {
		return this.props.mini ? scss.logo : null;
	}

	render() {
		return <Link
			disabled={!this.props.link}
			noDefaultClasses={this.props.noDefaultClasses}
			uri="/"
		>
			<Image
				alignItems="center"
				alt="BrandTrackr"
				className={`${this.logoMain()} ${this.miniLogo()} ${(this.darkTheme ? scss.logoDark : null)}`}
				error={<Logo />}
				noLoader={true}
				onClick={Navigator.home}
				src={(this.props.mini ? "/icon.png" : "/logo.png")}
				style={this.props.mini ? this.constructor.miniLogoStyles : this.constructor.regularLogoStyles}
				title="BrandTrackr"
			/>
		</Link>;
	}

	/**
	 * Get whether we're using dark theme.
	 * 
	 * @return {Boolean}
	 */
	get darkTheme() {
		return (this.props.theme.palette.type === "dark");
	}

	/**
	 * Regular logo styles.
	 * 
	 * @type {Object}
	 */
	static regularLogoStyles = {
		width: "18rem"
	};

	/**
	 * Mini-logo styles.
	 * 
	 * @type {Object}
	 */
	static miniLogoStyles = {
		width: rem(3)
	};

}


export default withTheme(ImageLogo);
