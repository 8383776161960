import Config from "Resources/Config.json";
import ContentQuery from "Content/ContentQuery.js";
import History from "./History.js";
import dContentQuery from "Dispatchers/dContentQuery.js";
import dRkey from "Dispatchers/dRkey.js";

/**
 * Navigator
 *
 * This is used to handle navigation changes including current URI re-render.
 * 
 * @package BrandTracker
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class Navigator {

	/**
	 * Get current URI.
	 *
	 * @return {String}
	 */
	static getCurrentUri() {
		return `${History.location.pathname}${History.location.search}`;
	}


	/**
	 * Navigate to the homescreen.
	 * 
	 * @return {void}
	 */
	static home() {
		Navigator.navigate(Config.uris.main);
	}


	/**
	 * Navigate to a new URI.
	 *
	 * This automatically handles reloading the current URI.
	 *
	 * @param {String} uri URI to navigate to
	 * @return {void}
	 */
	static navigate(uri) {

		dRkey();
		window.scrollTo({top: 0});

		if (((History.location.pathname === Config.uris.content) && (uri === Config.uris.content)) ||
			((History.location.pathname === "/admin/content") && (uri === "/admin/content"))) {
			dContentQuery(new ContentQuery());
		}

		if (uri !== this.getCurrentUri()) {
			History.push(uri);
		}

	}


	/**
	 * Reload the current URI.
	 * 
	 * @return {void}
	 */
	static reload() {
		this.navigate(this.getCurrentUri());
	}

}

export default Navigator;
