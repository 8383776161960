import React from "react";
import Loader from "Components/LoaderAbsolutelyCentred.js";

/**
 * Image component
 *
 * Loads an image with a loading spinner and a custom element on error.
 *
 * @package BrandTracker
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class Image extends React.PureComponent {

	/**
	 * State
	 * 
	 * @type {Object}
	 */
	state = {

		/**
		 * Error?
		 * 
		 * @type {Boolean}
		 */
		error: false,

		/**
		 * Loaded?
		 * 
		 * @type {Boolean}
		 */
		loaded: false

	};

	/**
	 * Error handler.
	 * 
	 * @return {void}
	 */
	handleError = () => {
		this.setState({error: true, loaded: true});
		if (this.props.onError) this.props.onError();
	};

	/**
	 * Load handler.
	 *
	 * @return {void}
	 */
	handleLoaded = () => {
		this.setState({loaded: true});
		if (this.props.onLoad) this.props.onLoad();
	};


	/**
	 * Component updated.
	 * 
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.src !== this.props.src) {
			this.setState({error: false, loaded: false});
		}
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<div style={this.containerStyles}>
				{((!this.state.error && this.props.src) && this.renderImg())}
				{(this.shouldRenderLoader ? this.renderLoader() : null)}
				{((this.state.error || !this.props.src) && this.props.error)}
			</div>
		);
	}


	/**
	 * Render image.
	 * 
	 * @return {ReactNode}
	 */
	renderImg() {
		return (
			<img
				alt={this.props.alt}
				className={this.props.className}
				onClick={this.props.onClick}
				onError={this.handleError}
				onLoad={this.handleLoaded}
				src={this.props.src}
				style={this.styles}
				title={this.props.title} />
		);
	}


	/**
	 * Render the loader.
	 * 
	 * @return {ReactNode}
	 */
	renderLoader() {
		return (
			<Loader
				size={25}
				vertical={true}
				verticalTransform={!this.props.noVerticalLoader} />
		);
	}


	/**
	 * Get height to render at.
	 * 
	 * @return {String} CSS
	 */
	get height() {
		return (this.props.height || (this.props.fullHeight ? "100%" : null));
	}


	/**
	 * Get whether we should render the loader.
	 * 
	 * @return {ReactNode}
	 */
	get shouldRenderLoader() {
		return (!this.props.noLoader && !this.state.loaded && this.state.src);
	}


	/**
	 * Styles.
	 * 
	 * @return {Object}
	 */
	get styles() {
		return {
			display: "block",
			height: this.height,
			objectFit: "contain",
			width: this.props.width,
			...this.props.style,
			opacity: (!this.state.loaded ? 0 : this.props.style?.opacity)
		};
	}


	/**
	 * Container styles.
	 * 
	 * @type {Object}
	 */
	get containerStyles() {
		return {
			...this.props.containerStyles,
			alignItems: ((this.state.error || !this.state.loaded) ? "center" : (this.props.containerStyles?.alignItems || this.props.alignItems || "flex-start")),
			display: "flex",
			height: "100%",
			justifyContent: "center",
			position: "relative",
			width: "100%"
		};
	}

}

export default Image;
