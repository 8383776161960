import React from "react";

/**
 * Component base class
 *
 * An extension of `React.PureComponent` which overrides `setState()` to 
 * ignore state changes which occur while the component is unmounted, 
 * intended for use when setting state during an async operation.
 *
 * See the source methods for details to be adhered to by children.
 *
 * @package BrandTracker
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class Component extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Mounted
		 *
		 * Children should not change this!
		 * 
		 * @type {Boolean}
		 */
		this._mounted = false;

	}


	/**
	 * `setState()` override
	 *
	 * We only set the state if we're currently mounted.
	 * 
	 * @param {Object} state
	 * @param {Function} callback optional
	 * @return {void}
	 */
	setState(state, callback=undefined) {
		if (this._mounted) {
			super.setState(state, callback);
		}
	}


	/**
	 * We're mounting; set `_mounted` accordingly.
	 *
	 * Children must ensure this is invoked when overridden.
	 *
	 * @return {void}
	 */
	componentDidMount() {
		this._mounted = true;
	}


	/**
	 * We're unmounting; set `_mounted` accordingly.
	 * 
	 * Children must ensure this is invoked when overridden.
	 * 
	 * @return {void}
	 */
	componentWillUnmount() {
		this._mounted = false;
	}

}

export default Component;
