import React from "react";
import {TextField as MuiTextField} from "@material-ui/core";

/**
 * Material `TextField` wrapper which defers change events until blurs
 *
 * @package BrandTracker
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class TextField extends React.PureComponent {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 * 
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Value
			 * 
			 * @type {String}
			 */
			value: (props.value || "")

		};

		/**
		 * Change timer
		 *
		 * @type {Integer|null}
		 */
		this.changeTimer = null;

		/**
		 * Method binds
		 */
		this.handleBlur = this.handleBlur.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);

	}


	/**
	 * Clear the change timer.
	 * 
	 * @return {void}
	 */
	clearChangeTimer() {
		if (this.changeTimer) {
			clearTimeout(this.changeTimer);
		}
	}


	/**
	 * Component updated.
	 * 
	 * @param {Object} prevProps
	 * @return {self}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.setState({value: this.props.value});
		}
	}


	/**
	 * Component unmounting.
	 * 
	 * @return {void}
	 */
	componentWillUnmount() {
		this.clearChangeTimer();
	}


	/**
	 * Blurred.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleBlur(e) {
		this.clearChangeTimer();
		if (this.state.value !== this.props.value) {
			if (this.props.onChange) {
				this.props.onChange(this.state.value, e);
			}
		}
	}


	/**
	 * Value changed.
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	handleChange(e) {

		if (this.props.onKeyPress) {
			this.props.onKeyPress(e.target.value);
		}

		if (this.props.reportChangeOnTimer) {

			this.clearChangeTimer();

			this.changeTimer = setTimeout(() => {
				if (this.changeTimer) {
					this.clearChangeTimer();
					this.props.onChange(this.state.value);
				}
			}, this.props.reportChangeOnTimer);

		}

		this.setState({value: e.target.value});

	}


	/**
	 * Key pressed.
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	handleKeyPress(e) {
		if ((e.which === 13) && this.props.reportChangeOnEnter) {
			this.clearChangeTimer();
			this.props.onChange(this.state.value);
		}
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<MuiTextField
				autoFocus={this.props.autoFocus}
				color={this.props.color}
				disabled={this.props.disabled}
				error={this.props.error}
				fullWidth={this.props.fullWidth}
				helperText={this.props.helperText}
				InputLabelProps={this.props.InputLabelProps}
				InputProps={this.props.InputProps}
				inputProps={this.props.inputProps}
				inputRef={this.props.inputRef}
				label={this.props.label}
				multiline={this.props.multiline}
				name={this.props.name}
				onBlur={this.handleBlur}
				onChange={this.handleChange}
				onKeyPress={this.handleKeyPress}
				onPaste={this.props.onPaste}
				placeholder={this.props.placeholder}
				required={this.props.required}
				rows={this.props.rows}
				rowsMax={this.props.rowsMax}
				size={this.props.size}
				type={this.props.type}
				value={this.state.value}
				variant={(this.props.variant || "outlined")} />
		);
	}

}

export default TextField;
