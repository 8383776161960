import React from "react";
import Container from "Components/Container.js";
import Dialog from "Components/Dialog.js";
import String from "Components/String.js";

/**
 * Login error due to 403
 *
 * @package BrandTracker
 * @subpackage Login
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class Login403Dialog extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				onClose={this.props.onClose}
				open={this.props.open}
				title="Account unavailable">
				<Container pb={0.5}>
					<String
						color="textSecondary"
						str={this.constructor.strings} />
				</Container>
			</Dialog>
		);
	}


	/**
	 * Strings.
	 * 
	 * @type {Array}
	 */
	static strings = [
		"Your user account is currently unavailable.",
		"Please contact support for further details."
	];

}

export default Login403Dialog;
