import React from "react";
import Component from "App/Component.js";
import Container from "Components/Container.js";
import Config from "Resources/Config.json";
import Navigator from "App/Navigator.js";
import ReactHelper from "Helpers/ReactHelper.js";
import Select from "Components/Select.js";
import dContentQuery from "Dispatchers/dContentQuery.js";
import withContentQuery from "Hoc/withContentQuery.js";
import withMobile from "Hoc/withMobile.js";
import {withRouter} from "react-router-dom";
import {IconButton, TextField} from "@material-ui/core";
import {Clear as ClearIcon, Search as SearchIcon} from "@material-ui/icons";
import styles from "./UiBarSearch.module.scss";

/**
 * UI search bar
 *
 * This is used within the desktop app bar as a global search function.
 *
 * @package BrandTracker
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class UiBarSearch extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Route changed
			 *
			 * @type {Boolean}
			 */
			routeChanged: false,

			/**
			 * Value
			 *
			 * @type {String}
			 */
			value: (this.props.contentQuery?.search || "")

		};

		/**
		 * Input ref
		 * 
		 * @type {ReactRef}
		 */
		this.ref = React.createRef();

		/**
		 * Timer ID
		 *
		 * @type {Integer|null}
		 */
		this.timer = null;

		/**
		 * Method binds
		 */
		this.handleIconClick = this.handleIconClick.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onChangeType = this.onChangeType.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.search = this.search.bind(this);

	}


	/**
	 * Component unmounting.
	 * 
	 * @return {void}
	 */
	componentWillUnmount() {
		super.componentWillUnmount();
		if (this.timer) clearTimeout(this.timer);
	}


	/**
	 * Component updated.
	 * 
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {
		if (prevProps.contentQuery?.search !== this.props.contentQuery?.search) {
			this.setState({value: this.props.contentQuery?.search});
		}
	}


	/**
	 * Icon button clicked.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleIconClick(e) {

		/**
		 * End here!
		 */
		e.stopPropagation();

		/**
		 * Action time...
		 */
		if (this.state.value) {
			this.search("");
			this.setState({value: ""});
		}
		else this.ref.current.focus();

	}


	/**
	 * Value changed.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	onChange(e) {
		this.setState({value: e.target.value});
		if (this.timer) clearTimeout(this.timer);
		this.timer = setTimeout(this.search, 1000);
	}


	/**
	 * Search type changed.
	 *
	 * @param {String} searchType Search type name
	 * @return {void}
	 */
	onChangeType(searchType) {
		this.updateContentQuery({searchType});
	}


	/**
	 * Key pressed.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	onKeyDown(e) {
		if (e.which === 13) {
			this.search();
		}
	}


	/**
	 * Search!
	 *
	 * @param {String} value optional Override state value
	 * @return {void}
	 */
	search(value=null) {
		value = ((value !== null) ? value : this.state.value);
		this.updateContentQuery({search: (value || null)});
	}


	/**
	 * Update content query and navigate to the content page.
	 * 
	 * @param {Object} obj
	 * @return {void}
	 */
	updateContentQuery(obj) {
		if (!this.isOnContentPage) {
			dContentQuery(obj);
			Navigator.navigate(Config.uris.content);
		}
		else this.props.updateContentQuery(obj);
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {

		const showSearchTypeSelect = this.shouldShowSearchTypeSelect;

		return (
			<Container
				columns={`auto ${(showSearchTypeSelect ? "13.5rem" : "")}`.trim()}
				spacing={(this.props.isMobile ? 0.5 : 1)}>
				<TextField
					color="primary"
					fullWidth={true}
					inputRef={this.ref}
					InputProps={this.InputProps}
					onChange={this.onChange}
					onKeyDown={this.onKeyDown}
					placeholder={this.placeholder}
					size="small"
					value={(this.state.value || "")}
					variant="outlined" />
				{(showSearchTypeSelect && this.renderSearchTypeSelect())}
			</Container>
		);

	}


	/**
	 * Render the search type mode selection box.
	 * 
	 * @return {ReactNode}
	 */
	renderSearchTypeSelect() {
		return (
			<Select
				onChange={this.onChangeType}
				options={this.constructor.searchTypeOptions}
				value={(this.props.contentQuery.searchType || "any")} />
		);
	}


	/**
	 * Get whether we're on the content page.
	 * 
	 * @return {Boolean}
	 */
	get isOnContentPage() {
		const pathname = this.props.location.pathname;
		return pathname.startsWith(Config.uris.content);
	}


	/**
	 * Get our icon.
	 * 
	 * @return {ReactNode}
	 */
	get icon() {
		return (
			<IconButton
				color="primary"
				onClick={this.handleIconClick}
				onMouseDown={this.constructor.handleIconMouseDown}
				size="small">
				{ReactHelper.render(this.iconComponent)}
			</IconButton>
		);
	}


	/**
	 * Get our icon component.
	 * 
	 * @return {ReactComponent}
	 */
	get iconComponent() {
		return (!this.state.value ? SearchIcon : ClearIcon);
	}


	/**
	 * `InputProps`.
	 * 
	 * @return {Object}
	 */
	get InputProps() {
		return {
			classes: this.constructor.classes,
			endAdornment: this.icon
		};
	}


	/**
	 * Get the placeholder text.
	 * 
	 * @return {String}
	 */
	get placeholder() {
		if (this.props.contentQuery?.Collection && this.isOnContentPage) {
			return (this.props.bpGt("lg") ? "Refine Results by Keyword" : "Refine Results");
		}
		else return (this.props.bpGt("lg") ? "Search Headlines & Text" : "Search...");
	}


	/**
	 * Get whether to show the search mode selection box.
	 * 
	 * @return {ReactNode}
	 */
	get shouldShowSearchTypeSelect() {
		return (this.props.width !== "md");
	}


	/**
	 * Icon clicked - hack to stop input blurs.
	 * 
	 * @param {Event} e
	 * @return {void}
	 */
	static handleIconMouseDown(e) {
		e.preventDefault();
	}


	/**
	 * Classes.
	 * 
	 * @type {Object}
	 */
	static classes = {root: styles.UiBarSearch};

	/**
	 * Search type options
	 * 
	 * @type {Array}
	 */
	static searchTypeOptions = [
		{
			label: "Exact Match",
			value: "exact"
		},
		{
			label: "All Words",
			value: "all"
		},
		{
			label: "Any Words",
			value: "any"
		}
	];

}

export default withContentQuery(withMobile(withRouter(UiBarSearch)));
