import React from "react";
import Loader from "./Loader.js";

/**
 * Loader, centred via absolute positioning
 *
 * @package BrandTracker
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class LoaderAbsolutelyCentred extends React.PureComponent {

	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return <Loader containerStyles={this.styles} size={this.props.size} />;
	}


	/**
	 * Styles.
	 * 
	 * @return {Object}
	 */
	get styles() {
		return {
			left: "50%",
			position: "absolute",
			top: (this.props.top || (this.props.vertical ? "50%" : undefined)),
			transform: `translateX(-50%)${(this.props.verticalTransform ? " translateY(-50%)": "")}`
		};
	}

}

export default LoaderAbsolutelyCentred;
