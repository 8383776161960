/**
 * CSS helper
 *
 * @package BrandTracker
 * @subpackage Helpers
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class Css {

	/**
	 * Set the CSS base colour.
	 *
	 * @param {String} colour CSS
	 * @return {void}
	 */
	static setBaseColour(colour) {
		document.documentElement.style.setProperty("--base-color", colour);
	}


	/**
	 * Set the CSS error highlight colour.
	 *
	 * @param {String} colour CSS
	 * @return {void}
	 */
	static setErrorHighlightColour(colour) {
		document.documentElement.style.setProperty("--error-highlight-color", colour);
	}


	/**
	 * Set the CSS paper colour.
	 *
	 * @param {String} colour CSS
	 * @return {void}
	 */
	static setPaperColour(colour) {
		document.documentElement.style.setProperty("--paper-color", colour);
	}


	/**
	 * Set the CSS theme colour.
	 *
	 * @param {String} colour CSS
	 * @return {void}
	 */
	static setThemeColour(colour) {
		document.documentElement.style.setProperty("--theme-color", colour);
	}

}

export default Css;
