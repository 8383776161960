import React from "react";
import Navigator from "App/Navigator.js";
import {Link as MuiLink, Tooltip, Typography} from "@material-ui/core";
import scss from "./Link.module.scss";

/**
 * Link component
 *
 * A wrapper around Material's `Link` which routes internally to 
 * the URI given by the `uri` prop when clicked.
 *
 * Refer to the source for details of the other available props.
 *
 * @package BrandTracker
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class Link extends React.PureComponent {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Method binds
		 */
		this.handleClick = this.handleClick.bind(this);

	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		const link = this.renderLink();
		if (!this.props.tooltip) return link;
		else return <Tooltip title={this.props.tooltip}>{link}</Tooltip>;
	}


	/**
	 * Render the main link.
	 *
	 * @return {ReactNode}
	 */
	renderLink() {
		return (
			<MuiLink
				className={this.props.className}
				classes={this.props.noDefaultClasses ? null : this.classes}
				onClick={this.handleClick}
				style={this.styles}
				tabIndex="0"
				{...this.forwards}>
				{this.renderLinkMain()}
			</MuiLink>
		);
	}


	/**
	 * Render the main link content.
	 * 
	 * return {ReactNode}
	 */
	renderLinkMain() {
		if (!this.props.typo) return (this.props.label || this.props.children);
		else return <Typography style={this.styles}>{(this.props.label || this.props.children)}</Typography>;
	}


	/**
	 * Handle a click on the link.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	handleClick(e) {

		// Events
		e.preventDefault();
		e.stopPropagation();
		if (this.props.disabled) return;

		// URI handling
		let {uri} = this.props;
		document.activeElement.blur();

		// Click handler
		if (this.props.onClick) {
			this.props.onClick(this.props.value);
			return;
		}

		// External link
		if (this.props.external) {
			if (!uri.startsWith("http")) {
				uri = `http://${uri}`;
			}
			window.open(uri, "_blank");
		}

		// Internal link
		else Navigator.navigate(uri);

	}


	/**
	 * Get our classes.
	 * 
	 * @return {Object}
	 */
	get classes() {
		return {
			root: (!this.props.disabled ? (!this.props.native ? scss.link : undefined) : scss.disabled)
		};
	}


	/**
	 * Get props to forward to the Material `Link`.
	 *
	 * @return {Object}
	 */
	get forwards() {
		const {color, title, variant} = this.props;
		return {color, title, variant};
	}


	/**
	 * Styles.
	 * 
	 * @return {Object}
	 */
	get styles() {
		return {
			fontSize: this.props.fontSize,
			fontWeight: (this.props.bold ? "bold" : undefined),
			userSelect: (this.props.selectable ? "text" : undefined),
			...this.props.style
		};
	}

}

export default Link;
