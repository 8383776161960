import React from "react";
import mobile from "Helpers/Mobile.js";
import {withWidth} from "@material-ui/core";

/**
 * Breakpoints
 *
 * @type {Array}
 */
const bps = ["xs", "sm", "md", "lg", "xl"];


/**
 * `withMobile()` HOC wrapper to wrap a component with `ComponentWithMobile`
 *
 * You must also wrap your component with Material UI's `withWidth()`!
 *
 * @param {ReactComponent} Component Component to wrap
 * @return {ReactComponent} Wrapped component (has an `isMobile` prop)
 */
const withMobile = Component => {

	/**
	 * Class wrapping a React component with `ComponentWithMobile`
	 *
	 * @package BrandTracker
	 * @subpackage Hoc
	 * @author Heron Web Ltd
	 * @copyright BrandTracker
	 */
	const cmw = class ComponentWithMobile extends React.PureComponent {

		/**
		 * Constructor.
		 *
		 * @param {Object} props
		 * @return {self}
		 */
		constructor(props) {
			super(props);

			/**
			 * Method binds
			 */
			this.bpGt = this.bpGt.bind(this);

		}


		/**
		 * Render.
		 *
		 * @return {ReactNode}
		 */
		render() {
			return (
				<Component
					bpGt={this.bpGt}
					isMobile={mobile(this.props.width)}
					{...this.props} />
			);
		}


		/**
		 * Get whether we're above a given breakpoint.
		 * 
		 * @param {String} bp
		 * @return {Boolean}
		 */
		bpGt(bp) {
			const i = bps.indexOf(bp);
			const ir = bps.indexOf(this.props.width);
			return (ir >= i);
		}

	};

	return withWidth()(cmw);

};

export default withMobile;
