import State from "./State.js";

/**
 * Reducer
 *
 * Redux reducer to handle state changes as a result of actions.
 * 
 * The state should always be an object of key-value pairs.
 *
 * @package BrandTracker
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class Reducer {

	/**
	 * Authenticated.
	 *
	 * @param {Object} state
	 * @param {Object} obj Action
	 * @param {String} obj.auth Authentication token
	 * @param {Object} obj.user User object from authentication API
	 * @return {Object}
	 */
	static auth(state, {auth, user}) {
		return {...state, auth, user};
	}


	/**
	 * Content data entry screen cache updated.
	 *
	 * @param {Object} state
	 * @param {Object} options.contentDataEntryCache
	 * @return {Object}
	 */
	static contentDataEntryCache(state, {contentDataEntryCache}) {
		return {...state, contentDataEntryCache};
	}


	/**
	 * Content query dispatcher.
	 *
	 * @param {Object} state
	 * @param {ContentQuery} options.query
	 * @return {Object}
	 */
	static contentQuery(state, {query}) {
		return {...state, contentQuery: query};
	}


	/**
	 * Content query dispatcher (NEW).
	 *
	 * @param {Object} state
	 * @param {ContentQuery} options.query
	 * @param {String} options.queryId optional
	 * @return {Object}
	 */
	static contentQueries(state, {query, queryId}) {

		const s = {
			...state,
			contentQueries: {
				...state.contentQueries,
				[queryId]: query
			}
		};

		if (query === undefined) {
			delete s.contentQueries[queryId];
		}

		return s;

	}


	/**
	 * Dark theme toggle.
	 * 
	 * @param {Object} state
	 * @param {Boolean} options.dark
	 * @return {Object}
	 */
	static dark(state, {dark}) {
		return {...state, dark};
	}


	/**
	 * Drawer reducer.
	 * 
	 * @param {Object} state
	 * @param {Object} options.drawer
	 * @return {Object}
	 */
	static drawer(state, {drawer}) {
		return {...state, drawer: {...state.drawer, ...drawer}};
	}


	/**
	 * F12 reducer.
	 *
	 * @param {Object} state
	 * @param {Boolean} options.f12
	 * @return {void}
	 */
	static f12(state, {f12}) {
		return {...state, f12};
	}


	/**
	 * Reset the state.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	static reset(state) {

		const retained = {};

		this.persistsReset.forEach(key => {
			retained[key] = state[key];
		});

		return {...State, ...retained};

	}


	/**
	 * Route key updater.
	 *
	 * @param {Object} state
	 * @return {Object}
	 */
	static rkey(state) {
		return {...state, rkey: (state.rkey + 1)};
	}


	/**
	 * S3 media updater.
	 * 
	 * @param {Object} state
	 * @param {Boolean} options.s3Media
	 * @return {void}
	 */
	static s3Media(state, {s3Media}) {
		return {...state, s3Media};
	}


	/**
	 * Statcounter updater.
	 *
	 * @param {Object} state
	 * @param {Boolean} options.statcounter
	 * @return {void}
	 */
	static statcounter(state, {statcounter}) {
		return {...state, statcounter};
	}


	/**
	 * Cookies updater.
	 * 
	 * @param {Object} state
	 * @param {Boolean} options.cookies
	 * @return {Object}
	 */
	static cookies(state, {cookies}) {
		return {...state, cookies};
	}


	/**
	 * Set the stream content.
	 *
	 * There is no check for validity.
	 *
	 * @param {Object} state
	 * @param {Array|null} options.stream
	 * @return {Object}
	 */
	static stream(state, {stream}) {
		return {...state, stream};
	}


	/**
	 * Add a subscription object to the stream.
	 *
	 * There is no check for duplication or validity.
	 * 
	 * @param {Object} state
	 * @param {Object} options.sub Subscription
	 * @return {Object} 
	 */
	static streamAdd(state, {sub}) {
		const stream = (state.stream || []);
		return {...state, stream: [...stream, sub]};
	}


	/**
	 * Remove a subscription object from the stream.
	 * 
	 * Matches on the subscription `Type`/`Uuid` properties.
	 * 
	 * @param {Object} state
	 * @param {Object} options.sub Subscription
	 * @return {Object}
	 */
	static streamRemove(state, {sub}) {
		state.stream = (state.stream || []).filter(s => {
			const type = (sub.Type === s.Type);
			const uuid = (sub.Uuid === s.Uuid);
			return !(type && uuid);
		});
		return {...state};
	}


	/**
	 * Change the table page size preference.
	 * 
	 * @param {Object} state
	 * @param {Integer} options.tablePageSize
	 * @return {Object}
	 */
	static tablePageSize(state, {tablePageSize}) {
		return {...state, tablePageSize};
	}


	/**
	 * Reduce the state as the consequence of an action.
	 *
	 * The `action` object must contain a key `type` containing 
	 * the name of a static method of this class to invoke to 
	 * reduce the state (the method is passed the same parameter 
	 * values as we receive here).
	 * 
	 * When no such action is given, or its value does not correspond 
	 * to a valid reducer method, no state reduction will be effected.
	 *
	 * @param {Object} state Current state
	 * @param {Object} action Action object
	 * @return {Object} Reduced state
	 */
	static reduce(state, action) {
		if (Reducer.hasOwnProperty(action.type)) {
			state = Reducer[action.type](state, action);
		}
		return state;
	}


	/**
	 * Basic reducer to assign key/value pairs from the `props` object 
	 * within an action to our current state - this should not be used 
	 * where a specialised reducer has been provided for an action.
	 *
	 * @param {Object} state
	 * @param {Object} obj.props Apply value pairs
	 * @return {Object}
	 */
	static set(state, {props}) {
		return {...state, ...props};
	}


	/**
	 * State values to persist even after a reset
	 * 
	 * @type {Array}
	 */
	static persistsReset = ["api", "cookies", "dark", "f12", "s3Media", "statcounter"];

}

export default Reducer;
