import React from "react";
import rem from "Helpers/Rem.js";
import styles from "Resources/Styles.json";
import {Box} from "@material-ui/core";

/**
 * Container
 * 
 * An abstract container component which renders its children inside a 
 * Material UI `Box` component in a `grid` layout with automatically 
 * spaced children; the layout is vertical by default but can be made 
 * horizontal by passing the `row` prop as `true`.
 *
 * Details of styling and available props - please see `styles()`.
 *
 * @package BrandTracker
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class Container extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Box {...this.forwards} ref={this.props.innerRef} style={this.stylesReal}>
				{this.props.children}
			</Box>
		);
	}


	/**
	 * Get forwardable props.
	 * 
	 * @return {Object}
	 */
	get forwards() {
		const props = {};
		this.constructor.forwards.forEach(prop => {
			if (this.props[prop]) {
				props[prop] = this.props[prop];
			}
		});
		return props;
	}


	/**
	 * Style definitions.
	 *
	 * @return {Object}
	 */
	get styles() {
		return {
			alignContent: this.props.alignContent,
			alignItems: (this.props.centre ? "center" : this.props.alignItems),
			alignSelf: this.props.alignSelf,
			bottom: this.props.bottom,
			display: (this.props.display ? this.props.display : "grid"),
			flexDirection: this.props.flexDirection,
			flexShrink: 0,
			gridAutoFlow: (this.props.row ? "column" : "row"),
			gridAutoRows: (!this.props.viewport ? "max-content" : this.props.gridAutoRows),
			gridGap: rem((this.props.spacing || 1)),
			gridTemplateColumns: (this.props.fitContent ? "max-content" : (this.props.singleColumn ? "100%" : this.props.columns)),
			gridTemplateRows: this.props.gridTemplateRows,
			justifyContent: this.props.justifyContent,
			justifyItems: (this.props.centre ? "center" : this.props.justifyItems),
			justifySelf: this.props.justifySelf,
			left: this.props.left,
			maxWidth: this.props.maxWidth,
			minHeight: ((this.props.viewport && !this.props.autoHeight) ? "100vh" : (this.props.mainView ? `calc(100vh - ${styles.ui_bar_height} - ${rem(1.5)})` : this.props.minHeight)),
			position: this.props.position,
			right: this.props.right,
			textAlign: (this.props.centre ? "center" : null),
			top: this.props.top,
			width: (this.props.fullWidth ? "100%" : this.props.width),
			zIndex: this.props.zIndex
		};
	}


	/**
	 * Style definitions (real object to apply, merged with `style` prop).
	 *
	 * @return {Object}
	 */
	get stylesReal() {
		return {...this.styles, ...this.props.style};
	}


	/**
	 * We support forwarding these props to the underlying `Box`.
	 * 
	 * @type {Array}
	 */
	static forwards = [
		"classes",
		"className",
		"onClick",
		"title",
		"mx",
		"my",
		"ml",
		"mb",
		"mt",
		"mr",
		"px",
		"py",
		"pl",
		"pb",
		"pt",
		"pr"
	];

}

export default Container;
