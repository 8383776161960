import React from "react";
import {connect} from "react-redux";

/**
 * `withDrawer()` HOC wrapper to wrap a component with drawer details
 *
 * @param {ReactComponent} Component Component to wrap
 * @return {ReactComponent} Wrapped component - see source for details
 */
const withDrawer = Component => {

	/**
	 * Class wrapping a React component with `ComponentWithDrawer`
	 * 
	 * @package BrandTracker
	 * @subpackage Hoc
	 * @author Heron Web Ltd
	 * @copyright BrandTracker
	 */
	const cmd = class ComponentWithDrawer extends React.PureComponent {

		/**
		 * Render.
		 * 
		 * @return {ReactNode}
		 */
		render() {
			return (
				<Component
					drawerOpen={this.props.drawer.open}
					drawerVisible={this.props.drawer.visible}
					{...this.props} />
			);
		}

	};

	return connect(({drawer}) => ({drawer}))(cmd);

};

export default withDrawer;
