import React from "react";
import Container from "Components/Container.js";
import Store from "App/Store.js";
import rem from "Helpers/Rem.js";
import dDrawer from "Dispatchers/dDrawer.js";
import {IconButton} from "@material-ui/core";
import {Menu as MenuIcon} from "@material-ui/icons";

const dimensions = rem(3);

const styles = {width: dimensions, height: dimensions};

const toggleDrawer = () => dDrawer({open: !Store.getState().drawer.open});

export default () => (
	<Container style={styles}>
		<IconButton onClick={toggleDrawer}>
			<MenuIcon />
		</IconButton>
	</Container>
);
