import React from "react";
import TextField from "Components/TextField.js";

/**
 * F12 input
 *
 * @package BrandTracker
 * @subpackage F12
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class F12Input extends React.Component {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<TextField
				InputLabelProps={this.constructor.InputLabelProps}
				label={this.props.label}
				onChange={this.props.onChange}
				placeholder={this.props.placeholder}
				value={this.props.value}
				variant="standard" />
		);
	}


	/**
	 * `InputLabelProps`
	 */
	static InputLabelProps = {shrink: true};

}

export default F12Input;
