import {Apps as CollectionsIcon} from "@material-ui/icons";
import {Person as ProfileIcon} from "@material-ui/icons";
import {Home as HomeIcon} from "@material-ui/icons";
import {Search as ContentIcon} from "@material-ui/icons";
import {Timeline as InsightsIcon} from "@material-ui/icons";

export default [
	{
		label: "Home",
		icon: HomeIcon,
		uri: "/"
	},
	{
		label: "Collections",
		icon: CollectionsIcon,
		uri: "/collections"
	},
	{
		label: "Content",
		icon: ContentIcon,
		uri: "/content"
	},
	{
		label: "Insights",
		icon: InsightsIcon,
		uri: "/insights"
	},
	{
		label: "Account",
		labelAdmin: "Admin",
		icon: ProfileIcon,
		uri: "/account",
		uriSynonyms: ["/admin"]
	}
];
