import React from "react";
import Container from "Components/Container.js";
import ImageLogo from "Components/ImageLogo";
import String from "Components/String.js";
import ReactHelper from "Helpers/ReactHelper.js";

/**
 * Generic view
 *
 * @package BrandTracker
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class GenericView extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container centre={true} pt={4} px={1} spacing={4} viewport={this.props.viewport}>
				{ReactHelper.render(this.props.icon, this.constructor.iconProps)}
				<Container spacing={2}>
					<String str={this.props.title} unlimitedLines={true} variant="h5" bold={true} />
					<String str={this.props.caption} unlimitedLines={true} variant="h6" />
				</Container>
				<ImageLogo link={true} noDefaultClasses={true} />
			</Container>
		);
	}


	/**
	 * Icon props.
	 * 
	 * @type {Object}
	 */
	static iconProps = {color: "primary", fontSize: "large"};

}

export default GenericView;
