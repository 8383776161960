import React from "react";
import Container from "Components/Container.js";
import Paper from "Components/Paper.js";

/**
 * Interstitial view
 *
 * @package BrandTracker
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class Interstitial extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container
				centre={true}
				justifySelf="center"
				px={1}
				py={1}
				singleColumn={true}
				style={this.constructor.styles}
				width="48rem">
				<Paper label={this.props.label}>
					{this.props.children}
				</Paper>
			</Container>
		);
	}


	/**
	 * Styles.
	 * 
	 * @type {Object}
	 */
	static styles = {margin: "0 auto"};

}

export default Interstitial;
