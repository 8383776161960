import React from "react";
import {FormControlLabel, FormGroup} from "@material-ui/core";

/**
 * Form control
 *
 * @package BrandTracker
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class FormControl extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<FormGroup row>
				<FormControlLabel
					control={this.props.children}
					label={this.props.label} />
			</FormGroup>
		);
	}

}

export default FormControl;
