import React from "react";
import Button from "Components/Button.js";
import ReactHelper from "Helpers/ReactHelper.js";
import {connect} from "react-redux";

/**
 * UI bar action button
 *
 * @package BrandTracker
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class UiBarAction extends React.Component {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Method binds
		 */
		this.handleClick = this.handleClick.bind(this);

	}


	/**
	 * Clicked.
	 *
	 * @return {void}
	 */
	handleClick() {
		this.props.onClick(this.props.action);
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Button
				label={this.label}
				onClick={this.handleClick}
				startIcon={ReactHelper.render(this.props.action.icon)}
				variant="text" />
		);
	}


	/**
	 * Get our label.
	 * 
	 * @return {String}
	 */
	get label() {
		if (!this.props.user.Admin) return this.props.action.label;
		else return (this.props.action.labelAdmin || this.props.action.label);
	}

}

export default connect(({user}) => ({user}))(UiBarAction);
