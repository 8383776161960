import React from "react";
import Container from "./Container.js";
import Typography from "./Typography.js";

/**
 * String component
 *
 * A component which accepts an `str` prop of a string or an array 
 * of strings which are then rendered as a collection of Material 
 * `Typography` components, customisable with the `variant` prop.
 *
 * The `Typography` components will be rendered inside a `Box`.
 *
 * @package BrandTracker
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class String extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container pb={this.props.pb} style={this.props.styleContainer}>
				{
					this.strings.map((string, key) => (
						<Typography
							color={this.props.color}
							lines={this.props.lines}
							key={key}
							style={this.style}
							title={this.props.title}
							unlimitedLines={this.props.unlimitedLines}
							variant={this.props.variant}>
							{string}
						</Typography>
					))
				}
			</Container>
		);
	}


	/**
	 * Get the strings for rendering.
	 *
	 * Casts the `str` prop to an array if it isn't already.
	 *
	 * @return {Array} Array of strings
	 */
	get strings() {
		const arr = Array.isArray(this.props.str);
		return (arr ? this.props.str : [this.props.str]);
	}


	/**
	 * Styles.
	 * 
	 * @return {Object}
	 */
	get style() {
		return {
			fontWeight: (this.props.bold ? "bold" : undefined),
			lineHeight: this.props.lineHeight,
			textAlign: (this.constructor.TEXT_ALIGNMENTS[this.props.align]),
			whiteSpace: this.props.whiteSpace,
			userSelect: (this.props.selectable ? "text" : undefined),
			...this.props.style
		};
	}


	/**
	 * Text alignments map
	 *
	 * Maps prop shorthands to CSS `text-align` values.
	 * 
	 * @type {Object}
	 */
	static TEXT_ALIGNMENTS = {c: "center", l: "left", r: "right"};

}

export default String;
