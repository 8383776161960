import api from "api.js";
import Urls from "Resources/Urls.json";

/**
 * Password reset service
 *
 * @package BrandTracker
 * @subpackage Services
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class PasswordResetService {

	/**
	 * Initiate a password reset for a given email address.
	 * 
	 * @param {String} email
	 * @return {Promise}
	 */
	static initiate(email) {
		return api.axios().post(`${Urls.api.pwr}/initiate/${email}`);
	}


	/**
	 * Submit a password reset attempt using a token.
	 *
	 * @param {String} token
	 * @param {String} email
	 * @param {String} password
	 * @return {Promise}
	 */
	static submit(token, email, password) {
		return api.axios().post(`${Urls.api.pwr}/${token}`, {email, password});
	}

}

export default PasswordResetService;
