import axios from "axios";
import dReset from "Dispatchers/dReset.js";
import snack from "App/Snackbar.js";
import Store from "App/Store.js";

/**
 * API class
 *
 * An abstraction around Axios to handle API interactions.
 * 
 * @package BrandTracker
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class Api {

	/**
	 * Make an API call (uses `axios()`).
	 *
	 * @param {Object} request Request object for Axios
	 * @param {Boolean} handleOn401 optional Logout on HTTP 401 when authenticated (means token expired) (`true`)
	 * @return {Promise} Axios request promise
	 */
	static call(request, handleOn401=true) {

		/**
		 * Axios instance
		 */
		const axios = this.axios();

		/**
		 * Apply interceptors
		 */
		axios.interceptors.response.use(

			/**
			 * Response handler
			 *
			 * @param {Object} response
			 * @return {Object}
			 */
			response => response,

			/**
			 * Error handler
			 *
			 * @param {Error} error
			 * @return {Error}
			 */
			error => {

				/**
				 * Error handled?
				 */
				const handled = false;

				/**
				 * Authentication errors
				 */
				if (error.response && handleOn401) {
					if (Store.getState().auth && (error.response.status === 401)) {
						dReset();
						snack("You've been logged out because your authenticated session expired.");
					}
				}

				/**
				 * Have we handled the error?
				 */
				if (!handled) return Promise.reject(error);
				else return undefined;

			}

		);

		/**
		 * Make the request
		 */
		return axios.request(request);

	}


	/**
	 * Create a new Axios instance with our configuration.
	 *
	 * This will use the base given by `base` and headers from `headers`.
	 *
	 * We will be authenticated when authentication is available.
	 *
	 * @return {Object}
	 */
	static axios() {
		return axios.create({
			baseURL: this.base,
			timeout: 30000,
			headers: this.headers
		});
	}


	/**
	 * Get our API base URI.
	 *
	 * The origin is given by `originApi`.
	 * 
	 * The scheme always matches our current scheme.
	 *
	 * @return {String}
	 */
	static get base() {
		return `${this.scheme}://${this.originApi}`;
	}


	/**
	 * Get the headers to add to requests.
	 * 
	 * @return {Object}
	 */
	static get headers() {
		return {
			"Authorization": Store.getState().auth,
			"Content-Type": "application/json"
		};
	}


	/**
	 * Get our current origin.
	 *
	 * @return {String}
	 */
	static get origin() {
		return window.origin.split("://")[1];
	}


	/**
	 * Get the API origin to use.
	 * 
	 * This uses use the value stored in the app's state when set.
	 * 
	 * Otherwise, we use the environment value (`REACT_APP_API`) when set.
	 * 
	 * Otherwise, we use the default production API environment.
	 *
	 * @return {String}
	 */
	static get originApi() {
		const stored = Store.getState().api;
		const env = process.env.REACT_APP_API;
		return (stored || env || `api.brandtrackr.com`);
	}


	/**
	 * Get our current scheme.
	 *
	 * @return {String}
	 */
	static get scheme() {
		return window.origin.split("://")[0];
	}

}

export default Api;
