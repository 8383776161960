import React from "react";
import ReactHelper from "Helpers/ReactHelper.js";
import Styles from "Resources/Styles.json";
import scss from "./UiBarMobile.module.scss";
import {BottomNavigation, BottomNavigationAction} from "@material-ui/core";
import {connect} from "react-redux";

/**
 * UI bar (mobile)
 *
 * Renders the bottom navigation bar for mobile devices.
 * 
 * Matches the API expected by `UiBar`.
 * 
 * @package BrandTracker
 * @subpackage Ui
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class UiBarMobile extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Method binds
		 */
		this.handleNavigate = this.handleNavigate.bind(this);

	}


	/**
	 * Handle navigation.
	 *
	 * @param {Event} e
	 * @param {Object} active Active action
	 * @return {void}
	 */
	handleNavigate(e, active) {
		this.props.onNavigate(active);
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<BottomNavigation
				className={scss.UiBarMobile}
				onChange={this.handleNavigate}
				showLabels={true}
				style={this.constructor.styles}
				value={this.props.active}>
				{this.renderActions()}
			</BottomNavigation>
		);
	}


	/**
	 * Render an action.
	 *
	 * @param {Object} action `UiActions.js`
	 * @param {mixed} key React node key
	 * @return {ReactNode}
	 */
	renderAction(action, key) {
		return (
			<BottomNavigationAction
				key={key}
				icon={ReactHelper.render(action.icon)}
				label={this.getActionLabel(action)}
				value={action} />
		);
	}


	/**
	 * Render the actions to an array of rendered actions.
	 *
	 * @return {Array} `ReactNode` of `UiActions.js`
	 */
	renderActions() {
		return this.props.actions.map((action, key) => (
			this.renderAction(action, key)
		));
	}


	/**
	 * Get action label.
	 *
	 * @param {Object} action
	 * @return {String}
	 */
	getActionLabel(action) {
		if (!this.props.user.Admin) return action.label;
		else return (action.labelAdmin || action.label);
	}


	/**
	 * Styles.
	 * 
	 * @type {Object}
	 */
	static styles = {
		bottom: 0,
		height: Styles.ui_bar_height_mobile,
		position: "fixed",
		width: "100%",
		zIndex: 1000
	};

}

export default connect(({user}) => ({user}))(UiBarMobile);
