import React from "react";
import Checkbox from "Components/Checkbox.js";
import dStatcounter from "Dispatchers/dStatcounter.js";
import {connect} from "react-redux";

/**
 * F12 Statcounter checkbox
 *
 * @package BrandTracker
 * @subpackage F12
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class F12Statcounter extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Checkbox
				checked={this.props.statcounter}
				label="Statcounter integration enabled"
				onChange={dStatcounter} />
		);
	}

}

export default connect(({statcounter}) => ({statcounter}))(F12Statcounter);
