import React from "react";
import F12Input from "./F12Input.js";
import dReset from "Dispatchers/dReset.js";
import dSet from "Dispatchers/dSet.js";
import {connect} from "react-redux";

/**
 * F12 API URI
 *
 * @package BrandTracker
 * @subpackage F12
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class F12Api extends React.Component {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Method binds
		 */
		this.handleChange = this.handleChange.bind(this);

	}


	/**
	 * Value changed.
	 * 
	 * @param {String} api
	 * @return {void}
	 */
	handleChange(api) {
		if (api !== this.props.api) {
			dReset();
			dSet({api: (api || null)});
		}
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<F12Input
				label="API URI"
				onChange={this.handleChange}
				placeholder="(Automatic)"
				value={(this.props.api || "")} />
		);
	}

}

export default connect(({api}) => ({api}))(F12Api);
