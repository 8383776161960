import React from "react";
import Container from "Components/Container.js";
import String from "Components/String.js";
import {Paper as PaperMui} from "@material-ui/core";

/**
 * Paper component
 *
 * @package BrandTracker
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class Paper extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<PaperMui style={this.constructor.styles}>
				<Container
					fullWidth={true}
					pb={(this.props.pb || 1)}
					px={(this.props.px || 2)}
					pt={2}
					singleColumn={true}
					spacing={2}
					{...this.props.ContainerProps}>
					{this.renderLabel()}
					{this.props.children}
				</Container>
			</PaperMui>
		);
	}


	/**
	 * Render the label.
	 * 
	 * @return {ReactNode}
	 */
	renderLabel() {
		if (!this.props.labelComponent && this.props.label) {
			return (
				<String
					align={this.props.labelAlign}
					bold={true}
					color={this.props.labelColor}
					lines={this.props.labelLines}
					str={this.props.label}
					variant={(this.props.labelVariant || "h6")} />
			);
		}
		else return this.props.labelComponent;
	}


	/**
	 * Styles.
	 * 
	 * @type {Object}
	 */
	static styles = {width: "100%", zIndex: 1};

}

export default Paper;
