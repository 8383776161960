import React from "react";
import {useSnackbar} from "notistack";

let useSnackbarRef;

const Notistack = props => {
	props.setUseSnackbar(useSnackbar());
	return null;
};

const NotistackRef = useSnackbar => (useSnackbarRef = useSnackbar);

export const SnackbarHost = () => <Notistack setUseSnackbar={NotistackRef} />;

export default (msg, options) => useSnackbarRef.enqueueSnackbar(msg, options);
