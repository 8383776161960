import React from "react";
import AuthService from "Services/AuthService.js";
import Button from "Components/Button.js";
import Component from "App/Component.js";
import Config from "Resources/Config.json";
import Container from "Components/Container.js";
import Form from "Components/Form.js";
import Login403Dialog from "./Login403Dialog.js";
import PasswordResetService from "Services/PasswordResetService.js";
import String from "Components/String.js";
import TextField from "Components/TextField.js";
import dAuth from "Dispatchers/dAuth.js";
import withMobile from "Hoc/withMobile.js";
import withSnackbar from "Hoc/withSnackbar.js";
import * as Sentry from "@sentry/react";
import {withRouter} from "react-router-dom";

/**
 * Login form
 *
 * This component handles all login interactions.
 *
 * @package BrandTracker
 * @subpackage Login
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class LoginForm extends Component {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Email value
			 *
			 * @type {String}
			 */
			email: "",

			/**
			 * Password value
			 *
			 * @type {String}
			 */
			password: "",

			/**
			 * Error code (HTTP)
			 *
			 * Unhandled errors should set to `true`.
			 * 
			 * @type {Integer|Boolean|null} `null` = no error
			 */
			error: null,

			/**
			 * Password reset mode
			 * 
			 * @return {ReactNode}
			 */
			pwr: false,

			/**
			 * Submitting
			 * 
			 * @type {Boolean}
			 */
			submitting: false

		};

		/**
		 * Form reference
		 *
		 * @type {ReactRef}
		 */
		this.form = React.createRef();

		/**
		 * Email input reference
		 *
		 * @type {ReactRef}
		 */
		this.emailRef = React.createRef();

		/**
		 * Password input reference
		 *
		 * @type {ReactRef}
		 */
		this.passwordRef = React.createRef();

		/**
		 * Method binds
		 */
		this.clearError = this.clearError.bind(this);
		this.handleEmail = this.handleEmail.bind(this);
		this.handlePassword = this.handlePassword.bind(this);
		this.handlePwr = this.handlePwr.bind(this);
		this.handlePwrCancel = this.handlePwrCancel.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.submitErrorHandler = this.submitErrorHandler.bind(this);

	}


	/**
	 * Clear error state.
	 * 
	 * @return {void}
	 */
	clearError() {
		this.setState({error: false});
	}


	/**
	 * Email address updated.
	 * 
	 * @param {String} email
	 * @return {void}
	 */
	handleEmail(email) {
		this.clearError();
		this.setState({email});
	}


	/**
	 * Password updated.
	 * 
	 * @param {String} password
	 * @return {void}
	 */
	handlePassword(password) {
		this.clearError();
		this.setState({password});
	}


	/**
	 * Password reset mode.
	 * 
	 * @return {void}
	 */
	handlePwr() {
		this.setState({pwr: true});
		if (this.emailRef?.current) {
			this.emailRef.current.focus();
		}
	}


	/**
	 * Password reset mode cancelled.
	 * 
	 * @return {void}
	 */
	handlePwrCancel() {
		this.setState({pwr: false});
		if (this.emailRef?.current) {
			this.emailRef.current.focus();
		}
	}


	/**
	 * Submit!
	 *
	 * @return {void}
	 */
	handleSubmit(e) {

		e.preventDefault();
		this.clearError();

		this.setState({submitting: true});
		if (this.props.onSubmitting) {
			this.props.onSubmitting();
		}

		if (!this.state.pwr) {
			this.handleSubmitLogin();
		}
		else this.handleSubmitPwr();

	}


	/**
	 * Submitter - logins.
	 * 
	 * @return {void}
	 */
	handleSubmitLogin() {
		this.submit(this.state.email, this.state.password);
	}


	/**
	 * Submitter - password resets.
	 * 
	 * @return {void}
	 */
	handleSubmitPwr() {
		PasswordResetService.initiate(this.state.email).then(() => {
			if (this.props.onSubmittingCancel) {
				this.props.onSubmittingCancel();
			}
			if (this.props.onWantsClose) {
				this.props.onWantsClose();
			}
			else this.setState({pwr: false});
			this.props.snack("A password reset link has been emailed to you.", "success");
		}).catch(this.submitErrorHandler).finally(() => {
			this.setState({submitting: false});
		});
	}


	/**
	 * Submit.
	 *
	 * @param {String} email
	 * @param {String} password
	 * @return {void}
	 */
	submit(email, password) {
		AuthService.auth(email, password).then(auth => {
			dAuth(auth);
			if (this.props.onWantsClose) {
				this.props.onWantsClose();
			}
			this.props.history.replace((this.props.target || Config.uris.content));
		}).catch(this.submitErrorHandler).finally(() => {
			this.setState({submitting: false});
		});
	}


	/**
	 * Submit error handler.
	 *
	 * @param {Event} e
	 * @return {void}
	 */
	submitErrorHandler(e) {

		if (this.props.onSubmittingCancel) {
			this.props.onSubmittingCancel();
		}

		if (e?.response?.status === 401) {
			if (this.passwordRef?.current) {
				this.passwordRef.current.removeAttribute("disabled");
				this.passwordRef.current.select();
			}
		}

		if (e?.response?.status === 404) {
			if (this.emailRef?.current) {
				this.emailRef.current.removeAttribute("disabled");
				this.emailRef.current.select();
			}
		}

		if (!this.constructor.knownErrors.includes(e?.response?.status)) {
			this.props.snack(`There was an error logging you in. Please check your device is online.\nResponse code: ${(e?.response?.status || "(None)")}`, "error");
			Sentry.captureException(e);
		}
		else this.setState({error: (e?.response?.status || true)});

	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Form
				centre={true}
				form={this.form}
				fullWidth={true}
				onSubmit={this.handleSubmit}
				singleColumn={true}
				spacing={2}>
				<Container
					fullWidth={true}
					singleColumn={true}
					spacing={2.5}>
					{(!this.state.pwr ? this.renderLogin() : this.renderPwr())}
					{((this.props.authRequiredWarning && !this.state.pwr) ? this.renderArw() : null)}
					{(this.state.pwr ? this.renderPwrWarning() : null)}
					<Container
						justifyContent="flex-end"
						row={true}>
						<Button
							disabled={(this.props.disabled || this.state.submitting)}
							label={(!this.state.pwr ? this.pwrLabel : "Back")}
							onClick={(!this.state.pwr ? this.handlePwr : this.handlePwrCancel)}
							variant="text" />
						<Button
							disabled={(this.props.disabled || this.state.submitting)}
							label={(!this.state.pwr ? "Login" : "Reset Password")}
							type="submit"
							variant="contained" />
					</Container>
				</Container>
				<Login403Dialog
					onClose={this.clearError}
					open={(this.state.error === 403)} />
			</Form>
		);
	}


	/**
	 * Render authentication required warning strings.
	 * 
	 * @return {ReactNode}
	 */
	renderArw() {
		return (
			<String
				align="l"
				color="textSecondary"
				lines={4}
				str={this.constructor.arwStrings} />
		);
	}


	/**
	 * Render the email input.
	 * 
	 * @return {ReactNode}
	 */
	renderEmailInput() {
		return (
			<TextField
				autoFocus={true}
				disabled={(this.props.disabled || this.state.submitting)}
				error={(this.state.error === 404)}
				helperText={this.emailHelperText}
				inputRef={this.emailRef}
				label="Email"
				onKeyPress={this.handleEmail}
				required
				type="email"
				value={this.state.email}
				variant="standard" />
		);
	}


	/**
	 * Render the main login form.
	 * 
	 * @return {ReactNode}
	 */
	renderLogin() {
		return (
			<Container fullWidth={true} singleColumn={true}>
				{this.renderEmailInput()}
				<TextField
					disabled={(this.props.disabled || this.state.submitting)}
					error={(this.state.error === 401)}
					helperText={this.passwordHelperText}
					inputRef={this.passwordRef}
					label="Password"
					onKeyPress={this.handlePassword}
					required
					type="password"
					value={this.state.password}
					variant="standard" />
			</Container>
		);
	}


	/**
	 * Render the password reset form.
	 * 
	 * @return {ReactNode}
	 */
	renderPwr() {
		return (
			<Container fullWidth={true} singleColumn={true}>
				{this.renderEmailInput()}
			</Container>
		);
	}


	/**
	 * Render the password reset warning text.
	 * 
	 * @return {ReactNode}
	 */
	renderPwrWarning() {
		return (
			<Container>
				<String
					align="l"
					bold={true}
					color="textSecondary"
					str="Password Reset" />
				<String
					align="l"
					color="textSecondary"
					lines={4}
					str={this.constructor.pwrStrings} />
			</Container>
		);
	}


	/**
	 * Get the helper text for the email address input.
	 * 
	 * @return {String|null}
	 */
	get emailHelperText() {
		if (this.state.error === 404) return "Unknown user account";
		else return "Email address you use to login.";
	}


	/**
	 * Get the helper text for the password input.
	 *
	 * @return {String|null}
	 */
	get passwordHelperText() {
		if (this.state.error === 401) return "Password incorrect!";
		else return "Your password (at least 8 characters long).";
	}


	/**
	 * Get the password reset button's label.
	 * 
	 * @return {String}
	 */
	get pwrLabel() {
		return (this.props.isMobile ? "Reset Password" : "Forgotten Password?");
	}


	/**
	 * Authentication required warning strings
	 * 
	 * @type {Array}
	 */
	static arwStrings = [
		"The content you're accessing requires you to login.",
		"Please login to your BrandTrackr account to continue."
	];

	/**
	 * Password change required warning strings
	 * 
	 * @type {Array}
	 */
	static pwrStrings = [
		"Please enter your account's email address.",
		"We will email you a link to reset your password."
	];

	/**
	 * Errors we know how to handle.
	 * 
	 * @type {Array}
	 */
	static knownErrors = [401, 403, 404];

}

export default withMobile(withRouter(withSnackbar(LoginForm)));
