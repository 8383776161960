import React from "react";
import Config from "Resources/Config.json";
import Container from "Components/Container.js";
import CookieBanner from "Components/CookieBanner.js";
import ErrorView from "Views/ErrorView.js";
import F12 from "F12/F12.js";
import Loader from "Components/ViewLoader.js";
import Navigator from "App/Navigator.js";
import Router from "App/Router.js";
import Statcounter from "Integrations/Statcounter.js";
import UiActions from "Ui/UiActions.js";
import UiBar from "Ui/UiBar.js";
import UiBarMobile from "Ui/UiBarMobile.js";
import rem from "Helpers/Rem.js";
import withMobile from "Hoc/withMobile.js";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import * as Sentry from "@sentry/react";

/**
 * Application base
 * 
 * @package BrandTracker
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class Application extends React.Component {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {String}
		 */
		this.state = {

			/**
			 * Active UI action
			 *
			 * @type {Object}
			 */
			activeAction: this.getActionFromUri()

		};

		/**
		 * Method binds
		 */
		this.handleNavigate = this.handleNavigate.bind(this);

		/**
		 * Components cache
		 */
		this.loader = <Loader />;

	}


	/**
	 * Component mounted.
	 *
	 * @return {void}
	 */
	componentDidMount() {

		/**
		 * Add Facebook/Instagram SDKs
		 */
		if (this.props.cookies) {
			document.head.appendChild(this.constructor.fbsdk());
			document.head.appendChild(this.constructor.igsdk());
		}

		/**
		 * Add Statcounter
		 */
		if (this.props.cookies && this.props.statcounter && process.env.REACT_APP_APP) {
			Statcounter.enable();
		}

	}


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @return {void}
	 */
	componentDidUpdate(prevProps) {

		if (prevProps.auth !== this.props.auth) {
			if (!this.props.auth && (this.uri !== Config.uris.main)) {
				Navigator.navigate(Config.uris.main);
			}
		}

		if (prevProps.location.pathname !== this.props.location.pathname) {
			this.setState({activeAction: this.getActionFromUri()});
		}

		if (prevProps.cookies !== this.props.cookies) {

			const fbsdkElement = document.head.querySelector("#fbsdk");
			const igsdkElement = document.head.querySelector("#igsdk");

			if (this.props.cookies) {
				if (!fbsdkElement) document.head.appendChild(this.constructor.fbsdk());
				if (!igsdkElement) document.head.appendChild(this.constructor.igsdk());
			}
			else {

				if (fbsdkElement) {

					window.FB = undefined;

					let scripts = Array.from(document.head.querySelectorAll("script"));
					scripts = scripts.filter(s => s.src.startsWith("https://connect.facebook.net"));

					for (const script of scripts) {
						document.head.removeChild(script);
					}

					const style = document.head.querySelector("[data-fbcssmodules]");
					if (style) document.head.removeChild(style);

				}

				if (igsdkElement) {

					let scripts = Array.from(document.head.querySelectorAll("script"));
					scripts = scripts.filter(s => s.src.startsWith("https://www.instagram.com"));

					for (const script of scripts) {
						document.head.removeChild(script);
					}

				}

				Statcounter.disable();

			}

		}

	}


	/**
	 * Get the action corresponding to the current URI.
	 * 
	 * @return {Object|null} Action from `UiActions`
	 */
	getActionFromUri() {

		/**
		 * Get ready
		 */
		const uri = this.props.location.pathname;
		const actions = [...UiActions].reverse();
		const action = this.constructor.matchAction(actions, uri);

		/**
		 * Attempt to match
		 */
		if (!action) return null;
		else if ((action.uri === "/") && (uri !== "/")) return null;
		else return action;

	}


	/**
	 * Handle bar navigation.
	 *
	 * @param {Object} action Action from `UiActions`
	 * @return {void}
	 */
	handleNavigate(action) {
		this.setState({activeAction: action});
		Navigator.navigate(action.uri);
	}


	/**
	 * Render.
	 *
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Container
				overflow={true}
				singleColumn={true}
				style={this.styles}
				viewport={true}>

				<UiBar
					actions={UiActions}
					onNavigate={this.handleNavigate} />

				<div key={this.props.rkey} style={this.constructor.contentContainerStyles}>
					<Sentry.ErrorBoundary fallback={<ErrorView noViewport={true} />} key={this.props.rkey}>
						<React.Suspense fallback={this.loader}>
							<Router />
						</React.Suspense>
					</Sentry.ErrorBoundary>
				</div>

				{(this.shouldRenderAppBar ? this.renderAppBar() : null)}
				{((this.props.cookies === null) && <CookieBanner />)}
				<F12 />

			</Container>
		);
	}


	/**
	 * Render the app bar.
	 * 
	 * @return {ReactNode}
	 */
	renderAppBar() {
		return (
			<UiBarMobile
				actions={UiActions}
				active={this.state.activeAction}
				onNavigate={this.handleNavigate} />
		);
	}


	/**
	 * Get whether we should render the app bar.
	 * 
	 * @return {Boolean}
	 */
	get shouldRenderAppBar() {
		return (this.props.auth && this.props.isMobile);
	}


	/**
	 * Styles.
	 *
	 * @return {Object}
	 */
	get styles() {
		return {
			gap: rem(2),
			gridAutoRows: "max-content",
			paddingBottom: ((this.props.auth && this.props.isMobile) ? this.constructor.py : 0)
		};
	}


	/**
	 * Get the current URI.
	 *
	 * @return {String}
	 */
	get uri() {
		return this.props.location.pathname;
	}


	/**
	 * Match an array of UI action objects against a given URI.
	 * 
	 * @param {Array} actions
	 * @param {String} uri
	 * @return {Object|null} Action object
	 */
	static matchAction(actions, uri) {
		return actions.find(atn => {
			let syn;
			if (uri.startsWith(atn.uri)) return true;
			else syn = atn.uriSynonyms?.find(u => uri.startsWith(u));
			return (!!syn);
		});
	}


	/**
	 * Create a `<script>` element that refers to the Facebook SDK.
	 *
	 * @return {ReactNode}
	 */
	static fbsdk() {
		const script = document.createElement("script");
		script.id = "fbsdk";
		script.type = "text/javascript";
		script.async = true;
		script.crossOrigin = "anonymous";
		script.src = "https://connect.facebook.net/en_GB/sdk.js#version=v8.0";
		return script;
	}


	/**
	 * Create a `<script>` element that refers to the Instagram Embeds SDK.
	 *
	 * @return {ReactNode}
	 */
	static igsdk() {
		const script = document.createElement("script");
		script.id = "igsdk";
		script.type = "text/javascript";
		script.async = true;
		script.crossOrigin = "anonymous";
		script.src = "https://www.instagram.com/embed.js";
		return script;
	}


	/**
	 * View padding.
	 *
	 * @type {String} CSS
	 */
	static py = rem(5);

	/**
	 * Content container styles.
	 * 
	 * @type {Object}
	 */
	static contentContainerStyles = {paddingTop: this.py};

}

export default connect(({auth, rkey, cookies, statcounter}) => ({auth, rkey, cookies, statcounter}))(withMobile(withRouter(Application)));
