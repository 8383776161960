import React from "react";
import LogoVanity from "Components/LogoVanity.js";
import rsr from "react-string-replace";
import {Typography as MuiTypography} from "@material-ui/core";

/**
 * Typography component
 *
 * Extends the Material UI Typography component to support additional props.
 *
 * Please refer to the source to obtain prop details.
 *
 * @package BrandTracker
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class Typography extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<MuiTypography
				className={this.props.className}
				color={this.props.color}
				style={this.styles}
				title={this.props.title}
				variant={this.props.variant}>
				{this.children}
			</MuiTypography>
		);
	}


	/**
	 * Get our children.
	 *
	 * This automatically converts `{brandtracker}` tags to vanity logos.
	 * 
	 * @return {ReactNode}
	 */
	get children() {
		return rsr(
			this.props.children,
			"{brandtracker}",
			(match, key) => <LogoVanity key={key} />
		);
	}


	/**
	 * Get styles.
	 *
	 * @return {Object}
	 */
	get styles() {
		return {
			display: ((this.props.lines >= 1) ? "-webkit-box" : null),
			fontWeight: (this.props.bold ? "bold" : "normal"),
			overflow: "hidden",
			textOverflow: (this.useLineClamping ? "ellipsis" : null),
			WebkitBoxOrient: "vertical",
			WebkitLineClamp: (this.useLineClamping ? (this.props.lines || 1) : null),
			whiteSpace: ((this.useLineClamping && ((this.props.lines || 1) === 1)) ? "nowrap" : null),
			...this.props.style
		};
	}


	/**
	 * Get whether to use line clamping.
	 * 
	 * @return {Boolean}
	 */
	get useLineClamping() {
		return (!this.props.unlimitedLines && (this.props.lines !== null));
	}

}

export default Typography;
