import React from "react";
import Container from "Components/Container.js";
import String from "Components/String.js";
import Strings from "Resources/Strings.json";
import F12Api from "./F12Api.js";
import F12Dark from "./F12Dark.js";
import F12S3Media from "./F12S3Media.js";
import F12Statcounter from "./F12Statcounter.js";
import Version from "Components/Version.js";

export default () => (
	<Container pb={1}>
		<Container pb={1}>
			<F12Api />
			<Container spacing="0rem">
				<F12Dark />
				<F12S3Media />
				<F12Statcounter />
			</Container>
		</Container>
		<Version appId={true} color="textSecondary" />
		<String color="textSecondary" lines={4} str={Strings.f12.caption} />
	</Container>
);
