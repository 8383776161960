import React from "react";
import Container from "Components/Container";
import {Paper, Typography} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import Link from "Components/Link.js";
import Button from "Components/Button";
import dCookies from "Dispatchers/dCookies.js";
import withMobile from "Hoc/withMobile.js";

const styles = {
	mobileView: {
		left: "50%",
		transform: "translateX(-50%)",
		maxWidth: "60rem"
	}
};

class CookieBanner extends React.Component {

	handleAccept() {
		dCookies(true);
	}

	handleReject() {
		dCookies(false);
	}

	render() {
		const {classes} = this.props;

		return <Container
			bottom="0"
			className={!this.props.isMobile ? classes.mobileView : ""}
			position="fixed"
			px={0}
			py={0}
			zIndex={9999}>
			<Paper elevation={3}>
				<Container px={1} py={1} gap={0.5}>
					<Typography>
						BrandTracker uses cookies to ensure you get the best experience.
						If you login, press "Accept" or access any content on our website, you agree to our use of cookies.
						Find out more about our <Link uri="/privacy-policy" color="secondary">privacy policy here</Link>.
					</Typography>

					<Container justifyContent="flex-end" row={true}>
						<Button onClick={this.handleReject} label="Reject" variant="contained" />
						<Button onClick={this.handleAccept} label="Accept" variant="contained" />
					</Container>
				</Container>
			</Paper>
		</Container>;
	}
}


export default withMobile(withStyles(styles)(CookieBanner));
