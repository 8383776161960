import "./index.scss";
import App from "./App/App.js";
import React from "react";
import ReactDOM from "react-dom";
import worker from "./worker.js";
import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_APP && process.env.REACT_APP_SENTRY_DSN) {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		release: process.env.REACT_APP_APP
	});
}

worker();
ReactDOM.render(<App />, document.getElementById("react"));
