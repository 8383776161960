import React from "react";
import Dialog from "Components/Dialog.js";
import F12Controls from "./F12Controls.js";
import dF12 from "Dispatchers/dF12.js";
import {connect} from "react-redux";

/**
 * F12 dialog
 *
 * @package BrandTracker
 * @subpackage F12
 * @author Heron Web Ltrd
 * @copyright BrandTracker
 */
class F12 extends React.PureComponent {

	/**
	 * Constructor.
	 * 
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Method binds
		 */
		this.handleKey = this.handleKey.bind(this);

	}


	/**
	 * Mounted.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		this.handleClose();
		window.addEventListener("keydown", this.handleKey);
	}


	/**
	 * Unmounting.
	 * 
	 * @return {void}
	 */
	componentWillUnmount() {
		this.handleClose();
		window.removeEventListener("keydown", this.handleKey);
	}


	/**
	 * Key pressed.
	 *
	 * @param {KeyboardEvent} e
	 * @return {void}
	 */
	handleKey(e) {
		if (e.ctrlKey && (e.key === "F12")) {
			this.handleOpen();
		}
	}


	/**
	 * Open.
	 *
	 * @return {void}
	 */
	handleOpen() {
		dF12(true);
	}


	/**
	 * Closed.
	 * 
	 * @return {void}
	 */
	handleClose() {
		dF12(false);
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Dialog
				noTransition={true}
				onClose={this.handleClose}
				open={!!this.props.f12}
				title="Developer tools">
				<F12Controls />
			</Dialog>
		);
	}

}

export default connect(({f12}) => ({f12}))(F12);
