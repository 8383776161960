import React from "react";
import Css from "Helpers/Css.js";
import Theme from "Resources/Theme.json";
import {connect} from "react-redux";
import {createMuiTheme, ThemeProvider as MuiThemeProvider} from "@material-ui/core";

/**
 * Theme provider
 *
 * @package BrandTracker
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class ThemeProvider extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * State
		 *
		 * @type {Object}
		 */
		this.state = {

			/**
			 * Theme object
			 *
			 * @type {Object}
			 */
			theme: this.theme

		};

	}


	/**
	 * Component mounted.
	 * 
	 * @return {void}
	 */
	componentDidMount() {
		this.updateCssColours();
	}


	/**
	 * Component updated.
	 *
	 * @param {Object} prevProps
	 * @param {Object} prevState
	 * @return {void}
	 */
	componentDidUpdate(prevProps, prevState) {
		if (this.props.dark !== prevProps.dark) {
			this.setState({theme: this.theme});
		}
		if (this.state.theme !== prevState.theme) {
			this.updateCssColours();
		}
	}


	/**
	 * Update HTML CSS colours.
	 * 
	 * @return {void}
	 */
	updateCssColours() {
		Css.setBaseColour(this.state.theme.palette.background.default);
		Css.setPaperColour(this.state.theme.palette.background.paper);
		Css.setThemeColour(this.state.theme.palette.primary.main);
		Css.setErrorHighlightColour(this.state.theme.palette.errorHighlight.main);
	}


	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<MuiThemeProvider theme={this.state.theme}>
				{this.props.children}
			</MuiThemeProvider>
		);
	}


	/**
	 * Get our theme.
	 * 
	 * @return {Object}
	 */
	get theme() {
		const theme = {...Theme.app};
		theme.palette = (this.props.dark ? Theme.dark : Theme.light);
		return createMuiTheme(theme);
	}

}

export default connect(({dark}) => ({dark}))(ThemeProvider);
