import {v4 as uuid} from "uuid";

/**
 * Content query object
 *
 * @package BrandTracker
 * @subpackage Content
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class ContentQuery {

	/**
	 * Constructor.
	 *
	 * @param {Object} props optional
	 * @return {self}
	 */
	constructor(props={}) {

		/**
		 * Brands
		 *
		 * @type {Array}
		 */
		this.Brands = [];

		/**
		 * Publishers
		 * 
		 * @type {Array}
		 */
		this.Publishers = [];

		/**
		 * Campaigns
		 * 
		 * @type {Array}
		 */
		this.Campaigns = [];

		/**
		 * Industries
		 * 
		 * @type {Array}
		 */
		this.Industries = [];

		/**
		 * Countries
		 *
		 * @type {Array}
		 */
		this.Countries = [];

		/**
		 * Regions
		 *
		 * @type {Array}
		 */
		this.Regions = [];

		/**
		 * Channels
		 *
		 * @type {Array}
		 */
		this.Channels = [];

		/**
		 * ContentTypes
		 *
		 * @type {Array}
		 */
		this.ContentTypes = [];

		/**
		 * ContentRegions
		 *
		 * @type {Object} Content region UUID => constraint type ID
		 */
		this.ContentRegions = {};

		/**
		 * Collection
		 * 
		 * @type {Object|null}
		 */
		this.Collection = null;

		/**
		 * Maximum content date
		 *
		 * @type {String|null} YYYY-MM-DD
		 */
		this.MaxContentDate = null;

		/**
		 * Minimum content date
		 *
		 * @type {String|null} YYYY-MM-DD
		 */
		this.MinContentDate = null;

		/**
		 * Maximum creation date
		 *
		 * @type {String|null} YYYY-MM-DD
		 */
		this.MaxCtimeDate = null;

		/**
		 * Minimum creation date
		 *
		 * @type {String|null} YYYY-MM-DD
		 */
		this.MinCtimeDate = null;

		/**
		 * Saved filter set UUID
		 *
		 * @type {String|null}
		 */
		this.Filuse = null;

		/**
		 * Constrain to only Content from Brands in the User's Stream
		 * 
		 * @type {Boolean}
		 */
		this.StreamBrands = false;

		/**
		 * Constrain to only Content from Publishers in the User's Stream
		 * 
		 * @type {Boolean}
		 */
		this.StreamPublishers = false;

		/**
		 * Approval constraint
		 *
		 * @type {Boolean|null}
		 */
		this.Approved = null;

		/**
		 * Screenshot availability constraint
		 *
		 * @type {Boolean|null}
		 */
		this.Screenshot = null;

		/**
		 * Screenshot source constraint
		 * 
		 * @type {Integer|null}
		 */
		this.ScreenshotSource = null;

		/**
		 * Tracker status constraint
		 * 
		 * @type {Integer|null}
		 */
		this.TrackerStatus = null;

		/**
		 * Limit
		 *
		 * @type {Integer}
		 */
		this.limit = 20;

		/**
		 * Offset
		 *
		 * @type {Integer}
		 */
		this.offset = 0;

		/**
		 * API sort order
		 *
		 * @type {String}
		 */
		this.sort = "-Pdate";

		/**
		 * Search query
		 *
		 * @type {String|null}
		 */
		this.search = null;

		/**
		 * Search type
		 * 
		 * @type {String}
		 */
		this.searchType = "any";

		/**
		 * Query UUID
		 *
		 * This identifies this query *instance*, not the combination 
		 * or values of filters - i.e. if you mutate this instance, 
		 * the UUID *will* stay the same.
		 * 
		 * @type {String}
		 */
		this.uuid = uuid();

		Object.assign(this, props);

	}


	/**
	 * Get as a content API submission.
	 * 
	 * @return {Object}
	 */
	getApiSubmission() {
		return {
			Brands: this.Brands.map(brand => brand.Uuid),
			Publishers: this.Publishers.map(publisher => publisher.Uuid),
			Campaigns: this.Campaigns.map(campaign => campaign.Uuid),
			Industries: this.Industries.map(industry => industry.Uuid),
			Countries: this.Countries.map(country => country.Id),
			Regions: this.Regions.map(region => region.Uuid),
			Channels: this.Channels.map(channel => channel.Uuid),
			ContentTypes: this.ContentTypes.map(ct => ct.Uuid),
			ContentRegions: Object.keys(this.ContentRegions).map(ContentRegionUuid => ({ContentRegionUuid, ConstraintType: this.ContentRegions[ContentRegionUuid]})),
			Collection: (this.Collection?.Uuid || null),
			MaxContentDate: this.MaxContentDate,
			MinContentDate: this.MinContentDate,
			MaxCtimeDate: this.MaxCtimeDate,
			MinCtimeDate: this.MinCtimeDate,
			Approved: this.Approved,
			Screenshot: this.Screenshot,
			ScreenshotSource: (this.ScreenshotSource || null),
			StreamBrands: this.StreamBrands,
			StreamPublishers: this.StreamPublishers,
			TrackerStatus: (this.TrackerStatus || null),
			limit: this.limit,
			offset: this.offset,
			sort: this.sort,
			search: this.search,
			searchType: this.searchType
		};
	}

}

export default ContentQuery;
