import React from "react";
import Navigator from "App/Navigator.js";
import {Button as ButtonMui, Tooltip} from "@material-ui/core";

/**
 * Button
 *
 * @package BrandTracker
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class Button extends React.PureComponent {

	/**
	 * Constructor.
	 *
	 * @param {Object} props
	 * @return {self}
	 */
	constructor(props) {
		super(props);

		/**
		 * Method binds
		 */
		this.handleClick = this.handleClick.bind(this);

	}


	/**
	 * Clicked.
	 * 
	 * @return {void}
	 */
	handleClick(e) {

		e.stopPropagation();

		if (this.props.uri) {
			Navigator.navigate(this.props.uri);
		}
		else if (this.props.onClick) {
			this.props.onClick();
		}

	}


	/**
	 * Render button.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Tooltip title={(this.props.tooltip || "")}>
				{this.renderButton()}
			</Tooltip>
		);
	}


	/**
	 * Render button.
	 * 
	 * @return {ReactNode}
	 */
	renderButton() {
		return (
			<ButtonMui
				autoFocus={this.props.autoFocus}
				color={(this.props.color || "primary")}
				disabled={this.props.disabled}
				endIcon={this.props.endIcon}
				onClick={this.handleClick}
				size={this.props.size}
				startIcon={this.props.startIcon}
				style={this.props.style}
				type={this.props.type}
				variant={(this.props.variant || "contained")}>
				{this.props.label}
			</ButtonMui>
		);
	}

}

export default Button;
