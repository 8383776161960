import React from "react";
import LoginView from "Login/LoginView.js";
import Routes from "./Routes.js";
import {connect} from "react-redux";
import {Route, Switch} from "react-router-dom";

/**
 * Router
 * 
 * @package BrandTracker
 * @subpackage App
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class Router extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Switch>
				{
					this.routes.map((route, key) => (
						<Route
							component={this.getRouteComponent(route)}
							exact={true}
							key={key}
							path={route.uri} />
					))
				}
			</Switch>
		);
	}


	/**
	 * Get the component to render for a given route object.
	 * 
	 * @param {Object} route
	 * @return {ReactComponent}
	 */
	getRouteComponent(route) {
		if (!this.props.auth && !route.public) {
			return LoginView;
		}
		else return route.component;
	}


	/**
	 * Get available routes.
	 * 
	 * @return {Array}
	 */
	get routes() {
		return Routes.filter(route => {
			if (route.publicOnly && this.props.auth) return false;
			else return true;
		});
	}

}

export default connect(({auth}) => ({auth}))(Router);
