import React from "react";
import Config from "Resources/Config.json";
import Interstitial from "Views/Interstitial.js";
import LoginForm from "./LoginForm.js";

/**
 * Login view
 *
 * @package BrandTracker
 * @subpackage Login
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class LoginView extends React.Component {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<Interstitial label="Login to BrandTrackr">
				<LoginForm
					authRequiredWarning={this.is403}
					target={this.target} />
			</Interstitial>
		);
	}


	/**
	 * Get whether we're rendering due to a 403.
	 * 
	 * @return {Boolean}
	 */
	get is403() {
		return (this.props.location.pathname !== Config.uris.login);
	}


	/**
	 * Get the login target URI.
	 * 
	 * @return {String}
	 */
	get target() {
		const uri = (!this.is403 ? Config.uris.content : this.props.location.pathname);
		return `${uri}${this.props.location.search}`;
	}

}

export default LoginView;
