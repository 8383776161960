import React from "react";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import GenericView from "./GenericView.js";

/**
 * Error view
 * 
 * A generic error view.
 * 
 * @package BrandTracker
 * @subpackage Views
 * @author Heron Web Ltd
 * @copyright React.Component
 */
class ErrorView extends React.Component {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<GenericView
				title="Error"
				caption={this.constructor.strings}
				icon={ErrorIcon}
				viewport={!this.props.noViewport} />
		);
	}


	/**
	 * Strings.
	 * 
	 * @type {Array}
	 */
	static strings = [
		"We're sorry - something's gone wrong.",
		"Please try what you were doing again."
	];

}

export default ErrorView;
