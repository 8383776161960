import React from "react";
import Link from "Components/Link.js";
import rem from "Helpers/Rem.js";
import scss from "./Logo.module.scss";
import {withTheme} from "@material-ui/core";

/**
 * Logo component
 *
 * @package BrandTracker
 * @subpackage Components
 * @author Heron Web Ltd
 * @copyright BrandTracker
 */
class Logo extends React.PureComponent {

	/**
	 * Render.
	 * 
	 * @return {ReactNode}
	 */
	render() {
		return (
			<span style={this.styles}>
				<Link
					className={this.getClasses(scss.logoBrand)}
					disabled={this.props.noLink}
					uri="/"
					variant={(this.props.variant || "h6")}>
					BrandTrack<span className={this.getClasses(scss.logoTracker)}>r</span>
				</Link>
			</span>
		);
	}


	/**
	 * Get our class names.
	 *
	 * @param {String} base Base class name
	 * @return {String}
	 */
	getClasses(base) {
		const classes = [(!this.darkTheme ? base : scss.linkAlternative)];
		if (this.props.noLink) classes.push("disabled");
		return classes.join(" ");
	}


	/**
	 * Get whether we're using dark theme.
	 * 
	 * @return {Boolean}
	 */
	get darkTheme() {
		return (this.props.theme.palette.type === "dark");
	}


	/**
	 * Styles.
	 * 
	 * @return {Object}
	 */
	get styles() {
		return {
			alignItems: "center",
			display: (this.props.inline ? "inline" : "flex"),
			height: (!this.props.inline ? rem(3) : null)
		};
	}

}

export default withTheme(Logo);
